import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopupComponent } from 'src/app/share/popups/confirmation-popup/confirmation-popup.component';
import { LookupService } from 'src/app/share/services/lookup/lookup.service';

@Component({
  selector: 'app-clms-contract-types',
  templateUrl: './clms-contract-types.component.html',
  styleUrl: './clms-contract-types.component.scss'
})
export class ClmsContractTypesComponent implements OnChanges {

  public readonly faEdit = faEdit;
  public readonly faEye = faEye;
  public readonly faTrash = faTrash;
  formType: string = 'delete';
  isOverlayOpen: boolean = false;
  tableData: any;
  selectedTypeData: any;
  errorMessage: string;
  @Input() masterData: any = [];
  @Output() updateData: EventEmitter<any> = new EventEmitter();
  @Input() selectedType: string;

  ngOnChanges(changes: SimpleChanges): void {
    this.tableData = changes['masterData']?.currentValue
  }

  constructor(private lookupService: LookupService,
    private model: NgbModal
  ) { }

  ngOnInit() {
    this.tableData = this.masterData;
  }

  deleteMasterData(type: any) {
    let dialogRef = this.model.open(ConfirmationPopupComponent, {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true,
    });
    if (dialogRef?.componentInstance) {
      dialogRef.componentInstance.message = `Do you want to delete this ${type.name}`;
      dialogRef.componentInstance.lableName = 'Confirm'
      dialogRef.result.then(
        (result: any) => {
          if (result) {
            this.deleteContractType(type._id);
          }
        },
        (reason: any) => {

        }
      );
    }
  }

  openOverlay(type: string, selectedRow: any) {
    this.isOverlayOpen = true;
    this.selectedTypeData = selectedRow
  }

  closeOverlay(isSaved: boolean) {
    if (isSaved) {
      this.updateData.emit(true);
    }
    this.isOverlayOpen = false
  }

  deleteContractType(typeId: string) {
    this.lookupService.deleteContractType(typeId)?.subscribe({
      next: (res) => {
        if (res?.success) {

          this.updateData.emit(true);
        }
      },
      error: (err) => {
        this.errorMessage = err.error?.error?.message;
      }
    })
  }
}
