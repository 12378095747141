import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';
import { ConfirmationPopupComponent } from 'src/app/share/popups/confirmation-popup/confirmation-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrl: './mfa.component.scss'
})
export class MfaComponent implements OnInit{
  @Input() isMFA          : boolean;
  @Input() parentData     : any;
  qrCode                  : any;
  tOtpid                  : any;
  isPanelOpen             : boolean;
  errorMessage            : any;
  userDetailsData         : any;
  mfaEnabledDetails       : any;
  mfaAutentication        : string = "step1";
  message                 : string;
  deviceForm             !: FormGroup;
  totpForm               !: FormGroup;
  loadingIcon             : boolean;
  isToster                : boolean;
  isMfa                   : boolean = false;
  @Output() closePanel    : EventEmitter<any> = new EventEmitter<any>();

  constructor(
   private userSettingService : UserSettingService,
   private fb        : FormBuilder,
   private modal           : NgbModal
  ) { }

  ngOnInit(): void {
    if (this.isMFA) {
      this.ismfaEnabledByUser();
    } else {
      this.isMfaEnabled();
    }
    this.createFormGroup();
    this.createTotpForm();
  }

  createFormGroup() {
    this.deviceForm = this.fb.group({
      deviceName: ['', [Validators.required]],
    });
  }

  createTotpForm() {
    this.totpForm = this.fb.group({
      totp: ['', [Validators.required,Validators.minLength(6)]],
    });
  }

  onCloseIconClick(value: boolean) {
    this.isPanelOpen = false;
    this.closePanel.emit(value);
  }

  getQrCode() {
    this.loadingIcon = true;
    const payload = {
      mfaDevice: this.deviceForm.value.deviceName
    }
    this.userSettingService.getqrcode(payload)?.subscribe({
      next: (res: any) => {
        if (res['success']) {
          this.loadingIcon = false;
          this.isToster = true;
          this.qrCode = res?.data.qrCode;
          this.tOtpid = res?.data.totpId;
          this.mfaAutentication = 'step3'
          this.message = 'Device added successfully';
        }
      },
      error: (error) => {
        this.loadingIcon = false;
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  isMfaEnabled() {
    this.userSettingService.ismfaEnabled()?.subscribe({
      next: (res: any) => {
          this.mfaEnabledDetails = res?.data ||{};
          this.isMfa = !this.mfaEnabledDetails?.isMfaEnabled && !this.isMFA;
      },
      error: (error) => {
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  ismfaEnabledByUser() {
    this.userSettingService.ismfaEnabledByUser(this.parentData?._id)?.subscribe({
      next: (res: any) => {
          this.mfaEnabledDetails = res?.data ||{};
          this.isMfa = !this.mfaEnabledDetails?.isMfaEnabled && !this.isMFA;
      },
      error: (error) => {
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  disableMfa(name: any) {
    let dialogRef = this.modal.open(ConfirmationPopupComponent, {
      keyboard: false,
      backdrop: 'static',
    });
    dialogRef.componentInstance.message = `Are you sure you want to disable MFA for "${name}"`;
    dialogRef.componentInstance.header = `Disable MFA`;
    dialogRef.componentInstance.lableName = 'Disable';
    dialogRef.result.then(
      (result) => {
        if (result) {
          this.loadingIcon = true;
          const payload = {
            mfaDevice: name
          };
          this.userSettingService.disableMfa(payload).subscribe({
            next: (res: any) => {
              if (res['success']) {
                this.loadingIcon = false;
                this.isToster = true;
                this.isMfaEnabled();
                this.message = 'MFA disabled successfully!';
              }
            },
            error: (error) => {
              this.loadingIcon = false;
              this.errorMessage = error?.error?.error?.message;
            }
          });
        }
      },
    );
  }
  
  enableMfa() {
    this.userSettingService.enableMfa()?.subscribe({
      next: (res: any) => {
        console.log(res)
      },
      error: (error) => {
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  disabledUserMfa() {
    this.loadingIcon = true;
    this.userSettingService.mfaDisabledByUser(this.parentData?._id).subscribe({
      next: (res: any) => {
        if (res['success']) {
          this.loadingIcon = false;
          this.isToster = true;
          this.ismfaEnabledByUser();
          this.message = 'MFA disabled successfully';
        }
      },
      error: (error) => {
        this.loadingIcon = false;
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  movetoNext(value: any) {
    if (value == 'step1') {
      this.mfaAutentication = 'step2'
    }
    if (value == 'step2') {
      this.getQrCode();
    }
  }

  qrDone(value: any) {
    this.isMfaEnabled();
    this.onCloseIconClick(value);
  }

  verifyTotp() {
    this.loadingIcon = true;
    const payload = {
      totp: this.totpForm.value.totp,
      totpId: this.tOtpid
    }
    this.userSettingService.validateTotp(payload).subscribe({
      next: (res: any) => {
        if (res['success']) {
          this.loadingIcon = false;
          this.isToster = true;
          this.message = 'MFA enabled successfully';
          this.qrDone(true);
        }
      },
      error: (error) => {
        this.loadingIcon = false;
        this.errorMessage = error?.error?.error?.message;
      }
    });
  }

  closeToster(value: any){
    this.isToster = false;
  }

  protected faBan = faBan

}
