import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-common-toggle-switch',
  templateUrl: './common-toggle-switch.component.html',
  styleUrl: './common-toggle-switch.component.scss',
})
export class CommonToggleSwitchComponent {
@Input() checked      : boolean;
@Input() tooltip      : any;
@Output() onChange    : EventEmitter<any> = new EventEmitter<any>();

  change(event: any) {
    this.onChange.emit(event.target.checked);
  }

  toggleTooltip(tooltip: any, text: string) {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open({text});
    }
  }
}
