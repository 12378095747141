import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as d3 from 'd3'
@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrl: './line-chart.component.scss'
})
export class LineChartComponent implements OnChanges {
  @Input() data: any = [];
  @Input() isLineChartLoder: any = [];
  tooltip: any;

  ngOnChanges(changes: SimpleChanges) {
    this.data = changes['data']?.currentValue;
    d3.select('.graph').select('svg').remove();
    if (this.data && this.data.length !== 0) {
      this.createChart();
    }
  }

  ngOnInit(): void {
    this.createToolTip();
  }

  createToolTip() {
    this.tooltip = d3.select('body').append('div')
      .attr('class', '.tooltip')
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .style('display', 'block')
      .style('background-color', 'white')
      .style('border', '1px solid #ccc')
      .style('color', 'black')
      .style('padding', '5px 10px')
      .style('border-radius', '1px')
      .style("z-index", '99')

  }


  createChart(): void {
    const margin = { top: 10, right: 30, bottom: 40, left: 40 };
    const width = 500 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;
    const svg = d3.select('.graph')
      .append('svg:svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .style('background-color', 'white')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
    const x = d3.scaleBand()
      .domain(this.data.map((d: any) => d.label))
      .range([0, width])
      .padding(0.1);
    const y = d3.scaleLinear()
      .domain([0, d3.max(this.data, (d: any) => Math.max(d.totalFilesSize, d.totalAssets, d.totalUsers)) ?? 1000000]).nice()
      .range([height, 0]);

    svg.append("g")
      .call(d3.axisLeft(y).tickFormat(d3.format(".2s")));

    svg.append('g')
      .selectAll('.horizontalGrid')
      .data(y.ticks())
      .enter()
      .append('line')
      .attr('class', 'horizontalGrid')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', d => y(d))
      .attr('y2', d => y(d))
      .attr('stroke', '#ccc')
      .attr('stroke-width', '1px')
      .attr('shape-rendering', 'crispEdges');


    const areaStorageSize = d3.area<any>()
      .x(d => x(d.label)! + x.bandwidth() / 2)
      .y0(y(0)!)
      .y1(d => y(d.totalFilesSize)!);
    const areaTotalAssets = d3.area<any>()
      .x(d => x(d.label)! + x.bandwidth() / 2)
      .y0(y(0)!)
      .y1(d => y(d.totalAssets)!);
    const areaTotalUsers = d3.area<any>()
      .x(d => x(d.label)! + x.bandwidth() / 2)
      .y0(y(0)!)
      .y1(d => y(d.totalUsers)!);

    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    svg.append('path')
      .datum(this.data)
      .attr('fill', 'wheat')
      .attr('d', areaStorageSize)

    svg.append('path')
      .datum(this.data)
      .attr('fill', 'salmon')
      .attr('d', areaTotalAssets);
    svg.append('path')
      .datum(this.data)
      .attr('fill', 'lightblue')
      .attr('d', areaTotalUsers);

    svg.append('path')
      .datum(this.data)
      .attr('fill', 'none')
      .attr('stroke', 'yellow')
      .attr('stroke-width', 1)
      .attr('d', this.createLine(x, y, 'totalFilesSize')).on('mouseover', (event, d) => {
        svg.selectAll('.circle')
          .data(this.data)
          .enter()
          .append('circle')
          .attr('class', 'circle')
          .attr('cx', (d: any) => x(d.label)! + x.bandwidth() / 2)
          .attr('cy', (d: any) => y(d.totalFilesSize)!)
          .attr('r', 2)
          .style('fill', 'grey');
        const mouseX = event.offsetX - margin.left;
        const index = Math.floor(mouseX / (width / this.data.length));
        const item = this.data[index];
        this.tooltip.style('display', 'block');
        this.tooltip.html(`<strong>${item.label}</strong><br>
                          Storage Size: ${item.totalFilesSize} ${item.units}`)
          .style('left', (event.pageX + 10) + 'px')
          .style('top', (event.pageY - 30) + 'px');
      })
      .on('mouseout', () => {
        svg.selectAll('.circle').remove();
        this.tooltip.style('display', 'none');
      });
    svg.append('path')
      .datum(this.data)
      .attr('fill', 'none')
      .attr('stroke', 'red')
      .attr('stroke-width', 2)
      .attr('d', this.createLine(x, y, 'totalAssets')).on('mouseover', (event, d) => {
        svg.selectAll('.circle')
          .data(this.data)
          .enter()
          .append('circle')
          .attr('class', 'circle')
          .attr('cx', (d: any) => x(d.label)! + x.bandwidth() / 2)
          .attr('cy', (d: any) => y(d.totalAssets)!)
          .attr('r', 2)
          .style('fill', 'grey');
        const mouseX = event.offsetX - margin.left;
        const index = Math.floor(mouseX / (width / this.data.length));
        const item = this.data[index];
        this.tooltip.style('display', 'block');
        this.tooltip.html(`<strong>${item.label}</strong><br>
                          Total Assets: ${item.totalAssets}`)
          .style('left', (event.pageX + 10) + 'px')
          .style('top', (event.pageY - 30) + 'px');
      })
      .on('mouseout', () => {
        svg.selectAll('.circle').remove();
        this.tooltip.style('display', 'none');
      });

    svg.append('path')
      .datum(this.data)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 4)
      .attr('d', this.createLine(x, y, 'totalUsers')).on('mouseover', (event, d) => {
        svg.selectAll('.circle')
          .data(this.data)
          .enter()
          .append('circle')
          .attr('class', 'circle')
          .attr('cx', (d: any) => x(d.label)! + x.bandwidth() / 2)
          .attr('cy', (d: any) => y(d.totalUsers)!)
          .attr('r', 4)
          .style('fill', 'grey');
        const mouseX = event.offsetX - margin.left;
        const index = Math.floor(mouseX / (width / this.data.length));
        const item = this.data[index];
        this.tooltip.style('display', 'block');
        this.tooltip.html(`<strong>${item.label}</strong><br>
                          Total Users: ${item.totalUsers}`)
          .style('left', (event.pageX + 10) + 'px')
          .style('top', (event.pageY - 30) + 'px');
      })
      .on('mouseout', () => {
        svg.selectAll('.circle').remove();
        this.tooltip.style('display', 'none');
      });


      this.createLegend(svg);

    
  }


  createLine(x: any, y: any, label: string) {
    let line = d3.line<any>()
      .x(d => x(d.label)! + x.bandwidth() / 2)
      .y(d => { return y(d[label])! });

    return line
  }


  createLegend(svg:any){
    const colorLegend = [
      { label: 'Storage Size', color: 'yellow' },
      { label: 'Total Documents', color: 'salmon' },
      { label: 'Total Users', color: 'lightblue' }
    ];
    const legend = svg.append('g')
      .attr('transform', 'translate(30 , -20)')
    const legendItems = legend.selectAll('.legend-item')
      .data(colorLegend)
      .enter()
      .append('g')
      .attr('class', 'legend-item')
      .attr('transform', (d: any, i: number) => `translate(${i * 120}, 300)`)
      .style('margin-bottom', '20px')
    legendItems.append('rect')
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', (d: { color: any; }) => d.color);
    legendItems.append('text')
      .attr('x', 15)
      .attr('y', 7)
      .text((d: { label: any; }) => d.label)
      .style('font-size', '12px')
      .style('font-family', 'Arial')
      .attr('alignment-baseline', 'middle');
  }

  convertStorage(size: any) {
    if (size) {
      let i = -1;
      let byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
        size /= 1024;
        i++;
      } while (size > 1024);
      return Math.max(size, 0.1).toFixed(1) + byteUnits[i];
    } else {
      return '0 GB';
    }
  }




}
