import { Component } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { StorageKeys } from '../share/enums/storage.enum';
import { StorageService } from '../share/services/storage-service/storage.service';
import { AuthService } from '../home/auth/auth.service';
import { faSignOut } from '@fortawesome/free-solid-svg-icons/faSignOut';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    private storageService: StorageService,
    public authService: AuthService,
    public router: Router
  ) {}
  protected readonly faBars = faBars;
  user: any = {};
  selectedCompany: any;

                  
 
  ngOnInit() {
    this.getSelfDetails();
    this.selectedCompany = this.user?.company;
  }

  getSelfDetails() {
    this.user = this.storageService.getDataFromLocalStorage(
      StorageKeys.SELF_DETAILS
    );

    //Here we need to check this.user.company & show the value in dropdown
  }

  onCompanyChange(event: any) {
    if (event?.target?.value) {
      this.user = this.storageService.getDataFromLocalStorage(
        StorageKeys.SELF_DETAILS
      );
      this.selectedCompany = event.target.value;
      this.user.company = this.selectedCompany;
      this.storageService.setDataToLocalStorage(
        StorageKeys.PRESENT_COMPANY,
        this.selectedCompany
      );
      this.storageService.setDataToLocalStorage(
        StorageKeys.SELF_DETAILS,
        this.user
      );
    }
  }
 

  protected readonly faSignOut = faSignOut;

  signOut() {
    this.storageService.clearLocalStorage();
    this.router.navigate(['login']);
  }
}
