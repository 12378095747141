import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-bread-crums',
  templateUrl: './bread-crums.component.html',
  styleUrl: './bread-crums.component.scss'
})
export class BreadCrumsComponent {
  @Input() breadCrumbs: any[] = [];
  @Output() breadCrumsClickEvent: EventEmitter<any> = new EventEmitter<any>();
  
  OnClickPath(value: any) {
    this.breadCrumsClickEvent.emit(value);
    if (value != 'Root') {
      let index = this.breadCrumbs.findIndex((x: any) => x._id == value?._id);
      this.breadCrumbs.splice(index + 1, this.breadCrumbs.length - (index + 1));
    }
  }

  getmentItem(value: any) {
    if (value.filter((x: any) => x?.name == 'Trash').length > 0) {
      return false;
    } else {
      return true;
    }
  }
}
