<div class="editRoles__container">
    <div class="editRoles__header">
        <div class="editRoles__title">File Extensions</div>
        <div (click)="handleOnClickClose()" class="editRoles__crossIcon">
            <img src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon" />
        </div>
    </div>
    <div class="editRoles__contentWrapper">
        <ng-container *ngFor="let item of preferencesData?.allowedFileTypes
        let index = index
        let last = last
        ">
        <div *ngIf="item?.availableExtensions.length > 0" [ngClass]="last ? 'no-border' : 'editRoles__cardContainer'">
            <div class="editRolesCard__container">
                <div class="file_types_block">
                    <div *ngIf="item?.status == 'Enabled' && item?.availableExtensions.length > 0" class="fileType">{{item?.fileType}}</div>
                    <ng-container *ngFor="let extension of item?.availableExtensions">
                        <div *ngIf="item?.status == 'Enabled'" class="extension-checkbox">
                            <input type="checkbox" [value]="extension"
                                   [checked]="item.enabledExtensions.includes(extension)"
                                   (change)="updateSelectedExtensions(item, extension)" />
                            {{extension}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        
        </ng-container>
    </div>
    <div class="editRoles__footer">
        <app-common-button buttonClass="smallbutton" buttonLabel="Cancel"
            (clickEvent)="closePanel.emit(false)"></app-common-button>
        <div *ngIf="commonService.orgLock() && hasEditAccess">
            <app-common-button buttonClass="smallFilledButton" buttonLabel="Save"   [loadingIcon]="verifyLoader"
                (click)="addFileExtensions()"></app-common-button>
        </div>
    </div>
</div>
<div class="toaster_message"  *ngIf="isToast">
    <app-error-toaster  [title]='message'
  (closeToaster)="closeToster()"></app-error-toaster>
  </div>