import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageKeys } from '../../enums/storage.enum';
import { StorageService } from '../storage-service/storage.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  user: any;
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.user = this.storageService.getDataFromLocalStorage(
      StorageKeys.SELF_DETAILS
    );
  }

  getPermissionsList() {
    let params = new HttpParams();
    params = params.append('restrictTo', environment.envType);
    let url = environment.API_URL + '/v1/permissions';
    return this.http.get(url,{ params: params });
  }

  getRoleList(id: number) {
    let url = environment.API_URL + '/v1/roles/list/' + id;
    return this.http.get(url);
  }

  createPermission(data: any, id: any) {
    let url = environment.API_URL + '/v1/roles/create/' + id;
    return this.http.post(url, data);
  }

  searchRoleListByName(id: number, searchText: any) {
    let params = new HttpParams();
    params = params.append('name', searchText);
    let url = environment.API_URL + '/v1/roles/list/' + id;
    return this.http.get(url, { params: params });
  }

  deleteRole(id: any) {
    let url = environment.API_URL + `/v1/roles/${id}`;
    return this.http.delete(url);
  }

  getAssignRole(userId: any, companyId: any) {
    let url = environment.API_URL + `/v1/user/${userId}/role/${companyId}`;
    return this.http.get(url);
  }

  getRolesById(id: number) {
    let url = environment.API_URL + '/v1/roles/' + id;
    return this.http.get(url);
  }

  assignRole(payload: any, userId: any, companyId: any) {
    let url = environment.API_URL + `/v1/user/${userId}/${companyId}/role`;
    return this.http.post(url, payload);
  }

  // /:user/:company/role/:role
  updateRole(id: number, data: any) {
    let url = environment.API_URL + '/v1/roles/' + id;
    return this.http.put(url, data);
  }

  deleteAssignRole(userId:any,companyId:any,roleId:any){
    let url = environment.API_URL + `/v1/user/${userId}/${companyId}/role/${roleId}`;
    return this.http.delete(url);
  }


  getPermission() {
    const allowedRoles = ['company.create'];
    if (allowedRoles?.length) {
      const userPermissions = this.user?.assignedTo?.[0]?.roles || [];
      let isUserAllowed = false;
      for (const chunk of userPermissions) {
        for (const key in chunk.subPermissions) {
          allowedRoles.forEach((allowedPermission) => {
            const [allowedModule, allowedPermissionKey] = allowedPermission.split('.');

            if (
              (chunk.module === allowedModule && chunk.enabled) ||
              (chunk.module === allowedModule &&
                chunk.permissions[key] &&
                allowedPermissionKey === key)
            ) {
              isUserAllowed = true;
            }
          });
        }
      }
      return isUserAllowed;
    }

    return true;
  }

}
