<div class="graph"></div>


<div class="custom-table-list-row-main" *ngIf="isLineChartLoder">
    <div class="custom-col-width-100 text-danger d-flex justify-content-center">
        <app-common-icon [loadingIcon]="true"></app-common-icon>
    </div>
</div>

<div class="color-red" *ngIf="(!data || data.length === 0) && !isLineChartLoder">
    <p>No Data Found !</p>
</div>
