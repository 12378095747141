<div class="off-canvas-content">
    <div class="offCanvas__container">
        <div class="offCanvas__contentWrapper">
            <div *ngIf="!isFileView" class="offCanvas__header">
                <div class="offCanvas__title">
                    Send Email
                </div>
                <img (click)="onCloseIconClick()" class="offCanvas__crossIcon"
                    src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon" />
            </div>

            <form class="mt-3" [formGroup]="sentEmailFormGroup">
                <div class="offcanvas__content">
                    <div class="offcanvas__orgDetails">
                        <div class="offCanvas__orgName-inputField">
                            <div class="form-group">
                                <label for="selectOption" class="filterOffcanvas__inputlabel">
                                    To<span style="color: chocolate;">*</span></label>
                                <div class="d-flex justify-content-start gap-2_adddropdown">
                                    <div  class="form-control form-select filterOffcanvas__inputlabel">
                                        <div (mouseleave)="showDropDown = false">
                                            <button class="drop-toggle btn flat" (click)="showDropdown()">
                                                <span class="spancolor" *ngIf="checkedMultiList.length<=0">
                                                   Select Email</span>
                                                <span class="spancolor" *ngIf="checkedMultiList.length>0">{{checkedMultiList.join(', ')}}</span>
                                                <small (click)="closeItem()" *ngIf="checkedMultiList.length>0" class="close-icon btn-close" aria-label="close"></small>
                                            </button>
                                            <div class="drop-show" *ngIf="showDropDown">
                                                <div class="usersFilter__branchesField">
                                                    <div class="usersFilter__branchWrappar">
                                                        <img class="usersFilter__searchIcon"
                                                            src="../../../../assets/Images/user-management/searchIcon.svg" alt="search"  />
                                                        <input (input)="handleFilter($event)" class="usersFilter__input" [formControl]="searchControl"
                                                        placeholder="Search"/>
                                                    </div>
                                                </div>
                                                  <div *ngIf="showEmailIcon" class="createPackage__InviteUser"
                                                  (click)="addItem(searchControl.value)">
                                                  <img src="../../../../assets/Images/create-package/Addor.svg" alt="add" />
                                                 Add External Email
                                                </div>
                                                <label *ngFor="let item of emailSearchList">
                                                    <app-checkbox checkboxStatus="{{item.checked}}"
                                                        (checked)="selectEmail($event.event.target.checked, item)"></app-checkbox>
                                                    <span>{{item.emailAddress}}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="sentEmailFormGroup.controls?.['to']?.invalid &&
                                                        (sentEmailFormGroup.controls?.['to']?.dirty ||
                                                        sentEmailFormGroup.controls?.['to']?.touched)" class="alert_required">
                                <div *ngIf="sentEmailFormGroup.controls?.['to']?.errors?.['required']">
                                    <small class="text-danger">Email is required.</small>
                                </div>
                                <div *ngIf="sentEmailFormGroup.controls?.['to']?.errors?.['pattern']">
                                    <small class="text-danger">Please enter a valid email address.</small>
                                </div>
                            </div>
                            </div>

                        <div class="offCanvas__orgName-inputField">
                            <app-input-filed label="Subject" placeholder="Subject"
                                [control]="$any(sentEmailFormGroup).controls['subject']"
                                [starIcon]="'*'"></app-input-filed>
                            <div *ngIf="sentEmailFormGroup.controls?.['subject']?.invalid &&
                                (sentEmailFormGroup.controls?.['subject']?.dirty ||
                                sentEmailFormGroup.controls?.['subject']?.touched)" class="alert_required">
                                <div *ngIf="sentEmailFormGroup.controls?.['subject']?.errors?.['required']">
                                    <small class="text-danger">Subject is required.</small>
                                </div>
                            </div>
                        </div>

                        <div class="offCanvas__orgName-inputField">
                            <app-description-field DescriptionData="Description"
                                [control]="$any(sentEmailFormGroup).controls['description']"
                                placeholder="Description"></app-description-field>
                        </div>

                        <div *ngIf="!isFileView">
                        <section class="editDocument__document">
                            <div class="editDocument__header">
                            <div *ngIf="envType == appType.sdex" class="editDocument__fileInfo" data-bs-toggle="tooltip"
                                [ngbTooltip]="largeFileName(selectedFile?.customFileName? selectedFile?.customFileName: selectedFile?.name, '')"
                                placement="top">
                                <p class="editDocument__filename">{{largeFileName(selectedFile?.customFileName ? selectedFile?.customFileName :
                                    selectedFile?.name, 'file')}} </p>
                            </div>
                            <div *ngIf="envType == appType.dams" class="editDocument__fileInfo" data-bs-toggle="tooltip"
                                [ngbTooltip]="largeFileName(selectedFile?.documentNumber? selectedFile?.documentNumber: selectedFile?.name, '')"
                                placement="top">
                                <p class="editDocument__filename">{{largeFileName(selectedFile?.documentNumber ? selectedFile?.documentNumber :
                                    selectedFile?.name, 'file')}} </p>
                            </div>
                            </div>
                            <div class="editDocument__image">
                              <img *ngIf="!selectedFile?.thumbnailUrl" src="../../../assets/Images/document-preview/Files.svg"
                                   alt="" [width]="230" [height]="133"/>
                              <img *ngIf="selectedFile?.thumbnailUrl" [src]="selectedFile?.thumbnailUrl" alt="" [width]="230"
                                   [height]="133"/>
                            </div>
                          </section>
                        </div>
                     

                        <small class="text-danger">{{errorMessage}}</small>
                    </div>
                </div>
            </form>
        </div>

        <div class="offCanvas__footer">
            <app-common-button (clickEvent)="onCloseIconClick()" buttonClass="smallbutton"
                buttonLabel="Cancel"></app-common-button>

            <app-common-button (clickEvent)="triggerMail()" buttonClass="smallFilledButton" buttonLabel="Sent"
                [loadingIcon]="verifyLoader" [disabled]="sentEmailFormGroup.invalid || !checkedMultiList.length"></app-common-button>
        </div>
    </div>
    
    <app-error-toaster *ngIf="isToster" (closeToaster)="closeToster($event)"  [title]="message"></app-error-toaster>