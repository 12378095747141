import { Component, EventEmitter,Input,OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { faPlus} from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/share/services/common/common.service';
import { LookupService } from 'src/app/share/services/lookup/lookup.service';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';

@Component({
    selector: 'app-add-lookup',
    templateUrl: './add-lookup.component.html',
    styleUrl: './add-lookup.component.scss',
})
export class AddLookupComponent implements OnInit {
  @Input() editItem    : any;
  @Input() companyDoc    : any;
  @Output() closePanel : EventEmitter<any> = new EventEmitter<any>();
  @Output() submitForm :EventEmitter<any> = new EventEmitter<any>();
  verifyLoader         : boolean = false;
  lookupFormGroup     !: FormGroup;
  showOptions          : boolean = false;
  fieldType            : string  = '';
  selectType           : any[] = ['Text', 'Number', 'Radio', 'CheckBox', 'TextArea'];
  addedFields          : any[] = [];
  metadataList         : any;
  errorMessage         : string;
  isLookup             : boolean = false;
  text                 : boolean;
  number               : boolean;
  textarea             : boolean;
  selfDetails          : any = {};
  message              :string ="";
  isToast              :boolean = false;
  protected readonly faPlus = faPlus;

  constructor(private _fb: FormBuilder,
             private lookupService :LookupService,
             private storageService :StorageService,
             public commonService   : CommonService,
    ) {}

  ngOnInit(): void {
    this.createFormGroup();
    if (this.editItem?._id) {
      this.editLookup(this.editItem);
      if (this.editItem.fieldType === 'Radio' || this.editItem.fieldType === 'CheckBox') {
        this.showOptions = true;
        this.addedFields = this.editItem.fieldValues;
      }
    }
    this.selfDetails = this.storageService.getDataFromLocalStorage('selfDetails');
  }

  createFormGroup() {
    this.lookupFormGroup = this._fb.group({
      metadata: new FormControl(),
      label: new FormControl('', [Validators.required]),
      fieldType: new FormControl('', [Validators.required]),
      validateRequire:new FormControl(false)
    });
  }

  editLookup(item: any) {
    this.isLookup = true;
    this.lookupFormGroup.patchValue({
     _id: item._id,
     label: item.label,
     fieldType: item.fieldType,
     validateRequire: item.validateRequire
   });
   this.fieldType = item.fieldType;
  }

  onCloseIconClick(value: any) {
    this.closePanel.emit(value);
    this.lookupFormGroup.reset();
    this.isLookup = false;
    this.showOptions = false;
    this.editItem = null;
    this.addedFields = [];
  }

  onChange(typeValue: any) {
    if (typeValue.target.value === 'Radio' || typeValue.target.value === 'CheckBox') {
      this.showOptions = true;
    } else {
      this.showOptions = false;
    }
  }

  addData() {
    if(this.lookupFormGroup?.controls['metadata'].value){
    this.addedFields.push(this.lookupFormGroup?.controls['metadata'].value);
    this.lookupFormGroup?.controls['metadata'].reset();
    }
  }

  isDuplicateField(): boolean {
    if (this.lookupFormGroup?.controls['metadata'].value) {
       const newValue = this.lookupFormGroup?.controls['metadata'].value.trim().toLowerCase();
         return this.addedFields.some(item => item.trim().toLowerCase() === newValue);
  }
    return false;
  }

  isChecked( event :any){
    this.lookupFormGroup.get('validateRequire')?.setValue(event.target.checked);
  }

  onSubmit() {
    this.verifyLoader = true;
    const payload = {
      _id: this.lookupFormGroup.value._id,
      label: this.lookupFormGroup.value.label,
      fieldValues: this.addedFields,
      fieldType: this.lookupFormGroup.value.fieldType,
      validateRequire: this.lookupFormGroup.value.validateRequire,
      companyDocId: this.companyDoc?._id
    }
    if (this.editItem?._id) {
      this.lookupService.editMetadata(payload, this.editItem._id).subscribe({
        next: (res) => {
          if (res) {
            this.metadataList = res.data;
            this.verifyLoader = false;
            this.editItem = null;
            this.onCloseIconClick(true);
            this.isToast =true;
            this.submitForm.emit({ success: true, message: 'Metadata updated successfully!' });
          }
        }, error: (err) => {
          this.errorMessage = err.error?.error?.message;
          this.verifyLoader = false;
          this.isToast = false;
        }
      });
    } else {
      this.lookupService.createMetadata(payload).subscribe({
        next: (res) => {
          if (res) {
            this.metadataList = res.data;
            this.verifyLoader = false;
            this.onCloseIconClick(true);
            this.isToast = true;
            this.submitForm.emit({ success: true, message: 'Metadata created successfully!' });

          }
        }, error: (err) => {
          this.errorMessage = err.error?.error?.message;
          this.verifyLoader = false;
          this.isToast =false;
        }
      })
      this.addedFields = [];
    }
  }

  deleteFieldValues(field: any) {
    this.addedFields = this.addedFields.filter(item => item !== field);
}

}



