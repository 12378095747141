<div class="listingPage__Head">
  <div class="listPage__Title">
    <div *ngIf="orgName">
      You are viewing {{headerTitle+' '}} of {{orgName}}
      <img class="close-icon" (click)="clearSearch()" *ngIf="orgName" src="../../../../assets/Images/user-management/Cross.svg" alt="">
    </div>
    <div *ngIf="!orgName">
      {{headerTitle}}
      <img class="close-icon" (click)="clearSearch()" *ngIf="dashBoard"  src="../../../../assets/Images/user-management/Cross.svg" alt="">
    </div>
  </div>
  <div class="listPage__Description" *ngIf="headerDescription">
    {{headerDescription}}
  </div>
</div>
