import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicEsignService {

  constructor(private http: HttpClient) { }

  getPaymentLink(payload: any) {
   console.log('payload', payload);

    let url = `${environment.API_ESIGN_URL}/v1/transaction/generateLink`;
    return this.http.post(url, payload);
  }
}
