import {Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BarController, BarElement, CategoryScale, ChartType, Decimation, Filler, Legend, Title, Tooltip } from 'chart.js';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnChanges {
  @Input() data: any;
  @Input() isPieChartLoder: any;
  @Input() legendPosition : any = 'bottom'
  @Input() chatWidth : string = '350px'
  @Input() showErrorMessage : boolean = true;
  svg: any;
  constructor() {
    Chart.register(BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip, ChartDataLabels);
  }
  public chart: Chart;
  ngOnChanges(changes: SimpleChanges) {
    this.data = changes['data']?.currentValue;
    if (this.data) {
      this.createChart(this.data)
    }
    if(changes['chatWidth']?.currentValue){
      this.chatWidth = changes['chatWidth']?.currentValue;
      this.createChart(this.data)
    }
  }
  getRandomColor() {
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    const random = array[0] / 2 ** 32;
    return '#' + Math.floor(random * 16777215).toString(16);
  }

  createChart(chartData: any) {
    let labels: any = []
    let values: any = []
    let colors: any = []
    chartData.forEach((x: any) => {
      labels.push(x.label)
      values.push(x.items)
      colors.push(this.getRandomColor());
    });
    const data = {
      labels: labels,
      datasets: [{
        label: 'Total',
        data: values,
        backgroundColor: colors,
        hoverOffset: 4
      }]
    };
    let chartExists = Chart.getChart("piecanvas");
    if (chartExists) {
      chartExists.destroy();
    }
    this.chart = new Chart("piecanvas", {
      type: 'pie' as ChartType,
      data: data,
      options: {
        responsive: true,
        plugins: {
          // datalabels: {
          //   formatter: function (value, ctx: any) {
          //     let sum = 0;
          //     let dataArr = ctx.chart.data.datasets[0].data;
          //     dataArr.map((data: any) => {
          //       sum += data;
          //     });
          //     let percentage = Math.round(value * 100 / sum) + "%";
          //     return percentage;
          //   },
          //   color: 'white',
          // },
          datalabels: {
            display: false,
            color: 'white',
          },
          // outlabels: {
          //   display: true
          // },
          legend: {
            position: this.legendPosition,
            labels: {
              boxWidth: 12,
            }

          },
          title: {
            display: true,
          },
        }
      },
    })
  }
}
