import { Component, EventEmitter, Input, Output} from '@angular/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { LookupService } from 'src/app/share/services/lookup/lookup.service';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrl: './module-list.component.scss'
})
export class ModuleListComponent {
  @Input() isModule        : boolean = false;
  @Output() editModuleItem : EventEmitter<any> = new EventEmitter<any>();
  moduleList               : any;
  errorMessage             : string;
  moduleItem               : any;
  public readonly faEdit = faEdit;

  constructor(
    private lookupService: LookupService
  ) { }

  ngOnInit(): void {
    this.getModuleList();
  }

  getModuleList() {
    this.isModule = false;
    this.lookupService.getModulelist()?.subscribe({
      next: (res) => {
        if (res.success) {
          this.moduleList = res.data;
        }
      }, error: (err) => {
        this.errorMessage = err.error?.error?.message;
      }
    })
  }

  editModuleList(item: any) {
    this.moduleItem = item;
    this.isModule = true;
  }

  onCloseClick(value: boolean) {
    if (value) {
      this.getModuleList();
    }
    this.isModule = false;
    this.moduleItem = null;
  }
}
