<div class="offCanvas__container">
    <div class="offCanvas__contentWrapper">
        <div class="offCanvas__header">
            <div class="offCanvas__title">
                {{ editItem ? 'Edit Metadata Field' : 'Add Metadata Field' }}
            </div>
            <img (click)="onCloseIconClick(false)" class="offCanvas__crossIcon"
                src="../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon" />
        </div>
        <form class="mt-3" [formGroup]="lookupFormGroup">
            <div class="offcanvas__content">
                <div class="offcanvas__orgDetails">
                    <div class="offCanvas__orgName-inputField">
                        <app-input-filed label="Label" placeholder="Enter label"
                            [control]="$any(lookupFormGroup).controls['label']" [value]="editItem?.label"
                            [starIcon]="'*'"></app-input-filed>
                    </div>
                    <div class="addUser__dropdown" *ngIf="lookupFormGroup?.controls?.['label']?.value">
                        <div class="addUser__dropdownLabel">
                            Select Type<span class="star">*</span>
                        </div>
                        <select  class="addUser__dropdownOptions" (change)="onChange($event)" formControlName="fieldType" [disabled]="!!editItem?._id">
                            <option value="" disabled selected>Please select type</option>
                            <option [disabled]="editItem?._id" *ngFor="let item of selectType" [value]="item"
                                [selected]="item === editItem?.fieldType">
                                {{ item }}
                            </option>
                        </select>
                    </div>
                    <ng-container *ngIf="showOptions">
                        <label class="label mt-3">Enter Options</label>
                        <div class="customInput">
                            <input class="inputfield" placeholder="Enter option" formControlName="metadata" >
                            <button type="button" (click)="addData()" class="btn btn-secondary button-custom-styles"[disabled]="isDuplicateField()">
                                <fa-icon [icon]="faPlus"></fa-icon>
                            </button>
                          </div>
                          <small class="text-danger" *ngIf="isDuplicateField()">Duplicate options are not allowed</small>
                        <div class="data">
                            <button class="btn btn-custom" *ngFor="let field of addedFields">{{field}}<button type="button" class="btn-close" aria-label="close" (click)="deleteFieldValues(field)"></button></button>
                        </div>
                    </ng-container>
                  </div>
                  <div class="checkBox" *ngIf="lookupFormGroup?.controls?.['label']?.value">
                    <input type="checkbox" (change)="isChecked($event)" formControlName="validateRequire"
                      [checked]="editItem?.validateRequire"> Required
                  </div>
                  <small *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</small>
                </div>        
        </form>
    </div>
    <div class="offCanvas__footer">
        <app-common-button (clickEvent)="onCloseIconClick(false)" buttonClass="smallbutton"
            buttonLabel="Cancel"></app-common-button>
        <app-common-button *ngIf="commonService.orgLock()" (clickEvent)="onSubmit()" [loadingIcon]="verifyLoader" buttonClass="smallFilledButton"
            buttonLabel="Submit" [disabled]="lookupFormGroup.invalid || (showOptions && !addedFields.length)"></app-common-button>
    </div>
</div>
