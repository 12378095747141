import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-auto-search-dropdown',
  templateUrl: './auto-search-dropdown.component.html',
  styleUrl: './auto-search-dropdown.component.scss' 
})
export class AutoSearchDropdownComponent implements OnInit, OnChanges {
  @Input() listData                  : any[];
  @Input() placeholder               : string;
  @Output() selectedValues           = new EventEmitter();
  @Output() searchText              = new EventEmitter();
  @Input() previousSeletedValues : any;
  @Input() showEmailIcon             : boolean = false;
  showDropDown                       : boolean = false;
  checkedList                        : any[];
  selectedIDs                        : any[];


  multiSelectedIDs                   : any[] = [];
  checkedMultiList                   : any = [];
  multiListData                      : any = [];
  searchControl : FormControl

  constructor() {
    this.checkedList = [];
    this.selectedIDs = [];
    this.checkedMultiList = [];
    this.multiSelectedIDs = [];
    this.searchControl = new FormControl('');
  }

  ngOnInit(): void {
    this.searchControl?.valueChanges.pipe(debounceTime(1000))
    .pipe(distinctUntilChanged()).subscribe((res: any) => {
       this.searchText.emit(this.searchControl.value);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.multiListData = this.listData;
    if(changes['previousSeletedValues']){
      this.multiSelectedIDs = changes['previousSeletedValues']?.currentValue || [];
      this.checkedMultiList = [];
      this.listData = this.listData?.map((item : any)=>{
        if(this.multiSelectedIDs?.includes(item._id)){
          item = {...item, checked: true}
          this.checkedMultiList.push(item.name);
        }else{
          item = {...item, checked: false}
        }
        return item;
      });
    }
  }

  getMultiSelectedValue(status: boolean, value: any) {
    if (status) {
      this.multiSelectedIDs.push(value._id)
      this.checkedMultiList.push(value.name);
      this.multiselectedList(status, value._id);
    } else {
      let idindex = this.multiSelectedIDs.indexOf(value._id);
      this.multiSelectedIDs.splice(idindex, 1);
      let index = this.checkedMultiList.indexOf(value.name);
      this.checkedMultiList.splice(index, 1);
      this.multiselectedList(status, value._id);
    }
    this.selectedValues.emit(this.multiSelectedIDs);
  }

  multiselectedList(status: boolean, id: any) {
    this.listData = this.listData?.map(x => {
      if (status) {
        if (x._id === id) {
          return { ...x, checked: true };
        } else {
          return x;
        }
      } else  if (x._id === id) {
          return { ...x, checked: false };
        } else {
          return x;
        }

    });
  }

  handleFilter(event: any) {
    this.handleFilter(event)
  
  }

  showDropdown() {
    this.multiListData = this.listData;
    this.showDropDown = !this.showDropDown
  }

  addItem(newEmail: any) {
    if (newEmail) {
      this.showEmailIcon = true;
      const newItem = { _id: newEmail, name: newEmail, checked: true };
      this.listData.push(newItem);
      this.multiSelectedIDs.push({emailAddress: newEmail});
      this.checkedMultiList.push(newEmail);
      this.selectedValues.emit(this.multiSelectedIDs);
      this.showEmailIcon = false;
      this.listData = [...this.listData]; 
      this.searchControl.reset();
    }
  }

}
