import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface Idata {
  label : string,
  value : number
}
@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrl: './custom-date-picker.component.scss'
})
export class CustomDatePickerComponent implements OnInit {

  @Input() numberOfDays :Idata[] = [];
  @Input() minDate :any =  new Date();
  @Output() selectedDates: EventEmitter<any> = new EventEmitter();

  selectedDateRange : number = 0;
  startDate: any;
  endDate : any;
  disabledDates: any;
  constructor(private datePipe : DatePipe){}

  ngOnInit(): void {
    this.startDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.disabledDates = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  handleSelectedDateRange(dateRange : number){
    if(this.selectedDateRange === dateRange){
      return;
    }
    this.selectedDateRange = dateRange;
    this.prepareStartAndEndDate(dateRange);
  }

  handleSelectedCustomDate(){
    this.selectedDateRange = -1;
    this.emitValues()
  }


  prepareStartAndEndDate(days: number){
    if(days === 0){
      this.startDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }else{
      let customDate = new Date();
      customDate.setDate(customDate.getDate() - days);
      this.startDate = this.datePipe.transform(customDate, 'yyyy-MM-dd');
      this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }

    this.emitValues()
  }

  emitValues(){
    this.selectedDates.emit({
      created_datefrom: this.startDate,
      created_dateupto: this.endDate
    })
  }
}
