import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import interact from 'interactjs';
import { PDFSource } from 'ng2-pdf-viewer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { faCaretLeft, faCaretRight, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-esign-positon',
  templateUrl: './esign-positon.component.html',
  styleUrl: './esign-positon.component.scss'
})
export class EsignPositonComponent {
  @Input() pdfSrc: string | Uint8Array | PDFSource;
  @Input() usersList: any = [];
  @Input() isShowUsers : boolean = false;
  @Input() isUniqDimensions : boolean = false;
  @Input() pageDimanesions : any = [];
  @Output() onClose = new EventEmitter<any>();
  page: FormControl = new FormControl(1);
  left: any;
  top: any;
  selectedPageType: any = 'LAST';
  selectedSignaturePosition: any = 'Bottom-Right';
  isPdfLoading: boolean = true;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  totalPages: number = 1;
  selectedUser: any = null;
  selectedUserIndex: number = 0;
  userPositionList: any;
  esignDivPosition: { left: number, top: number } = {
    left:0,
    top: 0
  }
  faTrash = faTrash;
  faPlusCircle = faPlusCircle;
  selectedSignPositionIndex : number = 0;
  thumbnailPdfview: boolean = true;
  @ViewChild('esignBoxElement', { static: false }) esignBoxElement: ElementRef;


  pageTypes: any = [
    { label: 'Select Page Type', value: undefined },
    { label: 'First', value: 'FIRST' },
    { label: 'Last', value: 'LAST' },
    { label: 'All', value: 'ALL' },
    { label: 'Even Pages', value: 'EVEN' },
    { label: 'Odd Pages', value: 'ODD' },
  ]

  signaturePositionTypes: any = [
    { label: 'Select Signature Position', value: undefined },
    { label: 'Top-Left', value: 'Top-Left' },
    { label: 'Top-Center', value: 'Top-Center' },
    { label: 'Top-Right', value: 'Top-Right' },
    { label: 'Middle-Right', value: 'Middle-Right' },
    { label: 'Middle-Left', value: 'Middle-Left' },
    { label: 'Middle-Center', value: 'Middle-Center' },
    { label: 'Bottom-Left', value: 'Bottom-Left' },
    { label: 'Bottom-Center', value: 'Bottom-Center' },
    { label: 'Bottom-Right', value: 'Bottom-Right' },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private render: Renderer2) {
  }

  ngOnInit() {
    if (this.usersList?.length) {
      this.selectedUser = this.usersList[0].emailAddress;
      this.usersList = this.usersList.map((user: any) => {
        return {
          ...user,
          signPositions: [
            {
              pageType: "LAST",
              signaturePosition: "Bottom-Right",
              isCustomPosition: false
            }
          ]
        }
      })
    }else{
      this.usersList = [
        {
          signPositions: [
            {
              pageType: "LAST",
              signaturePosition: "Bottom-Right",
              isCustomPosition: false
            }
          ]
        }
      ]
    }

  }

  ngAfterViewInit() {
    let position = {
      x: 0,
      y: 0
    }
    interact('.esign-box').draggable({
      onend: event => this.dragEndListener(event),
      listeners: {
        move: event => this.setDragPosition(position, event)
      }
    });
  }

  setDragPosition(position: any, event: any) {
    if (this.usersList[this.selectedUserIndex].signPositions[this.selectedSignPositionIndex].isCustomPosition) {
      position.x += event.dx;
      position.y += event.dy;
      this.verifyPosition(position);
      event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
    }
  }

  verifyPosition(position: any) {
    const dropZone: any = document.querySelector('.page');
    const rect = dropZone?.getBoundingClientRect();

    if (position.x < 0) {
      position.x = 0;
    } else if (position.y < 0) {
      position.y = 0;
    } else if (rect?.width < position.x) {
      position.x = rect.width
    } else if((rect?.height -60) < position.y){
      position.y = rect.height -60
    } else if(position.y && position.x < 130){
      position.x = 130
    }
  } 


  /**
 * This method calculates the PDF coordinates of a dragged element.
 * 
 * @param event - Contains details of the dragged element, including its final position.
 * 
 * Steps:
 * 1. Get the bounding rectangle of the PDF container.
 * 2. Extract the transform property of the dragged element to determine its final position.
 * 3. If a transform property exists, parse the translation values (left and top) from the matrix.
 * 4. Calculate the final PDF coordinates:
 *    - Add a padding of 18 pixels to the container width and add the translated left value.
 *    - Subtract the translated top value from the container height.
 *    - Multiply both values by 0.75 to convert from pixel coordinates to PDF coordinates.
 *      The factor 0.75 is derived from the assumption that the PDF has a resolution of 72 DPI
 *      and the screen resolution is 96 DPI, making 1 PDF point equivalent to 0.75 pixels.
 */
  dragEndListener(event: any) {
    if (!this.usersList[this.selectedUserIndex].signPositions[this.selectedSignPositionIndex].isCustomPosition) {
      return;
    }
    const dropZone: any = document.querySelector('.page');
    const rect = dropZone?.getBoundingClientRect();
    const transform: any = window.getComputedStyle(event.target).transform;
    let tLeft = 0, tTop = 0;

    if (transform !== 'none') {
      const matrix = transform.match(/matrix\((.+)\)/)?.[1].split(', ');
      tLeft = parseFloat(matrix?.[4]);
      tTop = parseFloat(matrix?.[5]);
    }

    this.left = tLeft -130;
    this.top = rect?.height - tTop

    let maxWidth = rect?.width;
    let maxHeight = rect?.height;

    if (maxWidth < this.left) {
      this.left = maxWidth;
    }

    if (maxHeight < this.top) {
      this.top = maxHeight;
    }
    
    if (this.usersList.length) {
      this.usersList[this.selectedUserIndex].signPositions[this.selectedSignPositionIndex] = {
        pageNumber: this.page.value,
        top: Math.ceil(this.top),
        left: Math.ceil(this.left) || 0,
        width: this.left + 120,
        height: this.top - 60,
        tLeft,
        tTop,
        isCustomPosition : true,
      }
    }
  }

  increasePageNumber() {
    if (this.page.value === this.totalPages) {
      return;
    }
    this.page.setValue(this.page.value + 1);
  }

  decreasePageNumber() {
    if (this.page.value === 1) {
      return;
    }
    this.page.setValue(this.page.value - 1);
  }

  successClose() {
    let returnData;
    if (this.isShowUsers) {
      returnData = this.usersList
    } else {
     returnData = this.usersList[0].signPositions
    }
    this.onClose.emit({ data: 'Sample Data' });
    this.activeModal.close(returnData);
  }


  callBackFn(event: any) {
    this.isPdfLoading = false;
    this.totalPages = event?._pdfInfo?.numPages
  }

  handleCustomPostion(event: any, signDetails: any) {
    signDetails.isCustomPosition = event?.event?.target?.checked
    this.render.setStyle(this.esignBoxElement.nativeElement, 'transform', 'translate(134px, 4px)');
    if (!signDetails.isCustomPosition) {
      this.render.setStyle(this.esignBoxElement.nativeElement, 'transform', 'translate(134px, 4px)');
    }
  }

  handleSelectDefaultPosition() {
    if (this.esignBoxElement?.nativeElement) {
      this.render.setStyle(this.esignBoxElement.nativeElement, 'transform', 'translate(134px, 4px)');
    }
  }

  handleSelectUser(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedUserIndex = selectElement.selectedIndex;
    let presentSign = this.usersList[this.selectedUserIndex]?.signPositions[0];
    this.selectedSignPositionIndex = 0;
    this.setEsignPosition(presentSign);
  }

  setEsignPosition(presentSign: any){
    if (presentSign && (presentSign?.pageType || presentSign?.signaturePosition)) {
      this.selectedPageType = presentSign?.pageType;
      this.selectedSignaturePosition = presentSign?.signaturePosition;
        this.render.setStyle(this.esignBoxElement.nativeElement, 'transform', 'translate(134px, 4px)');
    } else if (presentSign?.tLeft || presentSign?.tTop) {
      this.page.setValue(presentSign.pageNumber);
      if (this.esignBoxElement?.nativeElement) {
      this.render.setStyle(this.esignBoxElement.nativeElement, 'transform', `translate(${presentSign?.tLeft || 0}px, ${presentSign?.tTop || 0}px)`);
    }
  }
  }

  addNewSignPosition() {
    this.usersList[this.selectedUserIndex].signPositions.push(
      {
        pageType: "LAST",
        signaturePosition: "Bottom-Right",
        isCustomPosition: false
      }
    )
    this.selectedSignPositionIndex = this.usersList[this.selectedUserIndex].signPositions.length - 1
  }

  removeSignPosition(index: any) {
    this.usersList[this.selectedUserIndex].signPositions.splice(index, 1)
  }

  handlePositionSelection(index: number){
    this.selectedSignPositionIndex = index;
    let presentSign = this.usersList[this.selectedUserIndex]?.signPositions[this.selectedSignPositionIndex]
    this.setEsignPosition(presentSign);
  }

  thumbnail: any = [];
  pageRendered(e: any) {
    if(this.thumbnail.filter((data: any)=>data.page === e.pageNumber).length){
      return;
    }

    this.thumbnail.push({
      page: e.pageNumber,
      url: e.source.canvas.toDataURL()
    })

    if(this.totalPages === this.thumbnail.length){
      this.thumbnailPdfview = false;
    }
  }

  handleThumbnailClick(page: number){
    this.page.setValue(page);
  }

  handleEsignStyleClass(){
    return this.usersList[this.selectedUserIndex]?.signPositions[this.selectedSignPositionIndex]?.isCustomPosition
  }
}

