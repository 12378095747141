import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EsignPositonComponent } from 'src/app/utilities/esign-positon/esign-positon.component';
import { RegExpPatterns } from 'src/app/share/enums/regex-pattern.enum';
import {PublicEsignService} from '../../../share/services/public-esign/publicesign.service'
@Component({
  selector: 'app-public-esign',
  templateUrl: './public-esign.component.html',
  styleUrls: ['./public-esign.component.scss']
})
export class PublicEsignComponent {
  @Input() isFileUploading: boolean = false;
  @ViewChild('file') fileInput: any;
  
  userForm: FormGroup;
  allowedFileType: string = '.pdf';
  verifyLoader: boolean = false;
  uploadErrorMessage: string = '';
  uploadedFileName: string = '';
  selectedFile: any;

  constructor(private fb: FormBuilder,
     private modalService: NgbModal,
    private PublicEsignService: PublicEsignService)
 {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern(RegExpPatterns.MobilePattern)]]
    });
  }

  triggerFileInputClick() {
    this.fileInput.nativeElement.click();
  }

  upload(event: any) {
    const maxFileSizeBytes = 10 * 1024 * 1024; 
    const file = event?.target?.files?.[0];
    if (file) {
      const fileExtension = this.getFileExtension(file.name);
      if (fileExtension !== this.allowedFileType) {
        this.uploadErrorMessage = 'Only PDF files are allowed.';
        this.resetFileInput();
        return;
      }
      if (file.size > maxFileSizeBytes) {
        this.uploadErrorMessage = 'File size exceeds the maximum limit of 10 MB.';
        this.resetFileInput();
        return;
      }
      this.selectedFile = file;
      this.uploadedFileName = file.name;
      this.uploadErrorMessage = ''; 
    } else {
      this.uploadErrorMessage = ''; 
    }
    event.target.value = null;
  }

  getFileExtension(fileName: string): string {
    return `.${fileName.split('.').pop()?.toLowerCase()}`;
  }

  removeFile() {
    this.selectedFile = null;
    this.uploadedFileName = '';
    this.fileInput.nativeElement.value = '';
    this.uploadErrorMessage = '';
  }

  onSubmit() {
    if (this.selectedFile) {
      this.navigateToEsignPosition(this.selectedFile);
    } else {
      this.uploadErrorMessage = 'Please select a file before submitting.';
    }
  }

  navigateToEsignPosition(file: File) {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const pdfSrc = fileReader.result as string;
      const esignPositionPopup = this.modalService.open(EsignPositonComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        fullscreen: true,
      });
      
      if (esignPositionPopup.componentInstance) {
        esignPositionPopup.componentInstance.pdfSrc = pdfSrc;
        esignPositionPopup.componentInstance.isShowUsers = false;

        esignPositionPopup.result.then((result) => {
          if (result) {
            this.generatePaymentLink(this.userForm.value)
          }
        }).catch((error) => {
          console.error('Modal dismissed with error:', error);
        });

      }
    };
    fileReader.onerror = () => {
      this.uploadErrorMessage = 'Error reading the file.';
    };
    fileReader.readAsDataURL(file);
  }

  private resetFileInput() {
    this.fileInput.nativeElement.value = '';
    this.selectedFile = null;
    this.uploadedFileName = '';
  }

  generatePaymentLink(values: any){
     let formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    formData.append('name', values.name);
    formData.append('phoneNumber', values.phoneNumber);
    this.PublicEsignService.getPaymentLink(formData)?.subscribe({
      next: (res: any) => {
        if (res['success']) {
          window.open(res.data.short_url, "_blank");
        }
      },
      error: (err) => {
       
      },
    });
  }
}
