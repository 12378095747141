import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-file-extensions',
  templateUrl: './file-extensions.component.html',
  styleUrl: './file-extensions.component.scss'
})
export class FileExtensionsComponent implements OnInit {
  selectedExtensions: string[] = [];
  isToast         : boolean = false;
  @Output() closePanel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Input() preferencesData: any;
  @Input() verifyLoader: boolean;
  @Input() hasEditAccess: boolean = true;
  availableExtensions: any;
  message :any;

  constructor(public commonService : CommonService,){

  }
 
  ngOnInit(): void {
    this.preferencesData = this.preferencesData ? JSON.parse(JSON.stringify(this.preferencesData)) : null;
  }
  handleOnClickClose() {
    this.closePanel.emit(false);
  }

  updateSelectedExtensions(item: any, extension: string) {
    this.selectedExtensions = item.enabledExtensions
    const index = item.enabledExtensions.indexOf(extension);
    if (!item.enabledExtensions) {
      item.enabledExtensions = [];
    }

    if (index === -1) {
      item.enabledExtensions.push(extension);
    } else {
      item.enabledExtensions.splice(index, 1);
    }
  }


  addFileExtensions() {
    let temp = 0;
    this.preferencesData.allowedFileTypes.forEach((data: any)=>{
      if(data.enabledExtensions.length < 1){
        temp++ ;
      }
    })
    if(temp === this.preferencesData?.allowedFileTypes?.length){
      this.message = 'Please select at least one file extension';
      this.isToast = true;
    }else{
      this.onSave.emit(this.preferencesData);
    }
  }
  
  closeToster(){
    this.isToast = false;
  }

}
