import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LookupService } from 'src/app/share/services/lookup/lookup.service';
@Component({
  selector: 'app-clms-add-contract-type',
  templateUrl: './clms-add-contract-type.component.html',
  styleUrl: './clms-add-contract-type.component.scss'
})
export class ClmsAddContractTypeComponent implements OnInit, OnChanges {
  contractFormGroup        !: FormGroup;
  verifyLoader: boolean = false;
  errorMessage: string;
  isEdit: boolean = false;
  selectedDetails : any;
  @Input() selectedTypeData: any;
  @Input() selectedType: any;
  @Output() submitedData: EventEmitter<any> = new EventEmitter();
  @Output() closePanel: EventEmitter<any> = new EventEmitter();

  constructor(private _fb: FormBuilder,
    private lookupService: LookupService
  ) { }

  ngOnInit(): void {
    this.createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedTypeData']?.currentValue) {
      this.isEdit = true;
      this.selectedDetails = changes['selectedTypeData'].currentValue;
      this.patchContractType();
    }

    if (changes['selectedType']?.currentValue) {
      this.selectedType = changes['selectedType']?.currentValue === 'contracttype' ? 'contractType' : 'recordType';
    }
  }

  createFormGroup() {
    this.contractFormGroup = this._fb.group({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('')
    })
  }

  patchContractType() {
    if (this.selectedDetails?.name) {
      this.contractFormGroup?.patchValue({
        name:  this.selectedDetails.name,
        description:  this.selectedDetails?.description
      });
    }
  }

  editContractTypeData() {
    this.lookupService.updateContractType(this.contractFormGroup.value, this.selectedTypeData?._id)?.subscribe({
      next: (res) => {
        if (res) {
          this.verifyLoader = false;
          this.onCloseIconClick(true);
        }
      }, error: (err) => {
        this.errorMessage = err.error?.error?.message;
        this.verifyLoader = false;
      }
    });
  }

  addContractTypeData() {
    this.lookupService.addContractType({
      ...this.contractFormGroup.value,
      masterType: this.selectedType
    })?.subscribe({
      next: (res) => {
        if (res) {
          this.verifyLoader = false;
          this.onCloseIconClick(true);
        }
      }, error: (err) => {
        this.errorMessage = err.error?.error?.message;
        this.verifyLoader = false;
      }
    })
  }

  onCloseIconClick(value: any) {
    this.closePanel.emit(value);
    this.patchContractType();
  } 

  onSubmit() {
    this.verifyLoader = true;
    this.submitedData.emit(this.contractFormGroup.value);
    if (this.isEdit) {
      this.editContractTypeData();
    }else {
      this.addContractTypeData();
    }
  }
}
