export const environment = {
  production: false,
  rolesDisabled: false,
  scanDisabled: false,
  envType: 'SDEX',
  API_URL: `https://api-gateway.qa.sdex.in/api`,
  API_ESIGN_URL: `https://api.internal.sdex.in/public-esign`,
  IMAGE_URL: `https://axivise-images.s3.ap-south-1.amazonaws.com/app/`,
  PRIVATE_KEY : 'EQb8OsCjwgi8cTJikMWTXx5o4KXXG6dC'
};
