import { Component, Inject } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from './home/auth/auth.service';
import { environment } from 'src/environments/environment';
import { APPTYPES } from './share/constants/common-constants';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',

})
export class AppComponent {
  customUrldata: any;
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;
  constructor(private router: Router,   private authService: AuthService,    @Inject(DOCUMENT) private document: Document) {
    let definedRouter = this.router;
    this.router.events.subscribe(function (event) {

    });

    function setActiveTabs(tabId: string, type: string = 'onLoad'){
      let tabIds: any = localStorage.getItem("activeTabs")?.length ? localStorage.getItem("activeTabs")?.split(',') : [];
      if(type === 'returnActiveTabs'){
        return tabIds;
      }
      let tabIndex = tabIds.indexOf(tabId);
      if(tabIndex === -1 && type === 'onLoad'){
        tabIds.push(tabId);
        localStorage.setItem("activeTabs", tabIds.join(','));
      }else if(tabIndex !== -1 && type !== 'onLoad'){
        tabIds.splice(tabIndex, 1);
        localStorage.setItem("activeTabs", tabIds.join(','));
      }
      return tabIds;
    }

    function LogoutApplication(){
      localStorage.clear();
      definedRouter.navigate(['login']);
    }

    const bc = new BroadcastChannel("sdex-web-app");
    bc.onmessage = (event) => {
      if(event.data === 'logOut'){
        LogoutApplication();
      }else if (event.data !== window.sessionStorage["tabId"]) {
         localStorage.setItem("loadingNewWebsite", (new Date()).getTime().toString());
      }
    };

    window.onbeforeunload = function (e) {
      setActiveTabs(window.sessionStorage["tabId"], 'onUnLoad');
      window.sessionStorage["lastActiveTime"] = (new Date()).getTime().toString();
    };

    window.addEventListener("load", function (e)
    {
      if(!window.sessionStorage["tabId"]){
        window.sessionStorage["tabId"] = Date.now();
      }
      let activeTabIds = setActiveTabs(window.sessionStorage["tabId"], 'returnActiveTabs');
      if(!activeTabIds?.length && localStorage.getItem('token')){
        let lastActiveTime = window.sessionStorage["lastActiveTime"];
        if(!lastActiveTime || ((Math.floor((((new Date()).getTime()) - Number(lastActiveTime)) / 1000)) > 120) ){
          bc.postMessage("logOut");
          LogoutApplication();
        }
      }
      window.sessionStorage["lastActive"] = (new Date()).getTime().toString();
      setActiveTabs(window.sessionStorage["tabId"]);
    });
  }

  changeFavicon(newIconPath: string) {
      const favicon = this.document.getElementById('favicon') as HTMLLinkElement;
      if (favicon) {
          favicon.href = newIconPath;
      }
  }
  }

