<table class="custom-table-list" aria-label="cred_table">
  <thead>
    <tr class="custom-table-list-headings">
      <th class="custom-col-width-30">Category Name</th>
       <th class="custom-col-width-30">Metadata Fields</th>
      <th class="custom-col-width-20">Allowed File Format</th>
      <th class="custom-col-width-20">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr class="custom-table-list-row-main" *ngFor=" let value of documenttypelist">
      <td class="custom-col-width-30">{{value?.name}}</td>
      <td class="custom-col-width-30" >{{getMetaDataFields(value?.assignedLookups)}}</td>
      <td class="custom-col-width-20" >{{value?.allowedExtensions?.length || 'NA'}}</td>
      <td class="custom-col-width-20">
        <span class="d-flex justify-content-start">
          <span (click)="onEditDocumentType(value, 'view')" data-bs-toggle="tooltip" ngbTooltip="Metadata Fields" [placement]="'left'">
            <div class="btn btn-primary btn-sm me-2 fa-icon-class" style="background-color: #de7141; border-color: #de7141;">
              <fa-icon [icon]="faEye"></fa-icon>
            </div>
          </span>
          <span *ngIf="tabvalue === 'documenttype'" (click)="onEditDocumentType(value, 'editExt')" data-bs-toggle="tooltip" ngbTooltip="FileTypes"
            [placement]="'left'">
            <div class="btn btn-primary btn-sm me-2 fa-icon-class" style="background-color: #de7141; border-color: #de7141;">
              <fa-icon [icon]="faFile"></fa-icon>
            </div>
          </span>
           <span *ngIf="tabvalue === 'documenttype'" (click)="onEditDocumentType(value, 'dynamicRegex')" data-bs-toggle="tooltip" ngbTooltip="Doc Format"
            [placement]="'left'">
            <div class="btn btn-primary btn-sm me-2 fa-icon-class" style="background-color: #de7141; border-color: #de7141;">
              <fa-icon [icon]="faChain"></fa-icon>
            </div>
          </span>
          <span (click)="onEditDocumentType(value)" data-bs-toggle="tooltip"
          ngbTooltip="Edit" [placement]="'left'">
          <div tooltipClass="custom-tooltip" class="btn btn-primary btn-sm me-2"
            style="background-color: #de7141; border-color: #de7141;">
            <fa-icon [icon]="faEdit"></fa-icon>
          </div>
        </span>
          <span  *ngIf="tabvalue === 'contracttype' || tabvalue === 'recordtype'"(click)="onEditDocumentType(value, 'DELETE')" data-bs-toggle="tooltip" ngbTooltip="DELETE" [placement]="'left'">
            <div class="btn btn-primary btn-sm me-2 fa-icon-class" style="background-color: #de7141; border-color: #de7141;">
              <fa-icon [icon]="faTrash"></fa-icon>
            </div>
          </span>
        </span>
      </td>
    </tr>
  </tbody>
</table>
<div class="overlay" *ngIf="isDocumentType" [class.show]="isDocumentType"></div>
<div class="off-canvas {{formType === 'edit' ? 'width-450' : 'width-640'}}" *ngIf="isDocumentType" [class.show]="isDocumentType">
  <div class="off-canvas-content" *ngIf="formType === 'edit'">
    <app-add-document-type [hasEditAccess]="hasEditAccess" [tabvalue]="tabvalue" [documentType]="documentType" (closePanel)="onCloseIconClick($event)" (submitForm)="onSubmit($event)"></app-add-document-type>
  </div>
  <div class="off-canvas-content" *ngIf="formType === 'dynamicRegex'">
    <app-custom-regex [hasEditAccess]="hasEditAccess" [documentType]="documentType" (onSave)="updateRegex($event)" (closePanel)="onCloseIconClick($event)"></app-custom-regex>
  </div>
  <div class="off-canvas-content" *ngIf="formType === 'editExt'">
    <app-file-extensions [hasEditAccess]="hasEditAccess" [verifyLoader]="verifyLoader" (onSave)="updateExtensions($event)" [preferencesData]="enabledExtensions" (closePanel)="onCloseIconClick($event)"></app-file-extensions>
  </div>
</div>
<div class="toaster_message" *ngIf="isToaster">
  <app-toast [title]="uploadErrorMessage" (closeToaster)="closeToster($event)" ></app-toast>
</div>