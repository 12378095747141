<div [ngStyle]="{'width': chatWidth, 'display': 'block'}" >
    <canvas id="piecanvas">{{ chart }}</canvas>
</div>

<div class="custom-table-list-row-main" *ngIf="isPieChartLoder">
    <div class="custom-col-width-100 text-danger d-flex justify-content-center">
        <app-common-icon [loadingIcon]="true"></app-common-icon>
    </div>
</div>

<div class="color-red error-message" *ngIf="(!data || data.length === 0) && !isPieChartLoder && showErrorMessage">
    <p>There are currently No files/documents</p>
</div>



