import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-common-articles-card',
  templateUrl: './common-articles-card.component.html',
  styleUrls: ['./common-articles-card.component.scss']
})

export class CommonArticlesCardComponent {

  @Input() articleCardData  : any;

  openArticle(data: any) {
    if (data.title == 'Manage Vault') {
      window.open('https://sdex-public-documents.s3.ap-south-1.amazonaws.com/Document+Vault+Module.pdf', "_blank", "noopener");
    }
    else if (data.title == 'Org. structure') {
      window.open('https://sdex-public-documents.s3.ap-south-1.amazonaws.com/Org+Structure+Module.pdf', "_blank", "noopener");
    }
    else if (data.title == 'Add New Network') {
      window.open('https://sdex-public-documents.s3.ap-south-1.amazonaws.com/Networks+Module.pdf', "_blank", "noopener");
    }
    else if (data.title == 'Create Package') {
      window.open('https://sdex-public-documents.s3.ap-south-1.amazonaws.com/Packages+Module.pdf', "_blank", "noopener");
    }
  }
}
