<app-offcanvas-header paddingClass="paddingClass" header="{{isEdit ? 'Update' : 'Add'}} {{selectedType === 'recordType' ? 'Record' : 'Contract'}} Type"
                    (closeClicked)="onCloseIconClick(false)"></app-offcanvas-header>
<div class="offCanvas__container">
    <div class="offCanvas__contentWrapper">
        <form class="mt-2" [formGroup]="contractFormGroup">
            <div class="offcanvas__content">
                <div class="offcanvas__orgDetails">
                    <div class="offCanvas__orgName-inputField">
                        <app-input-filed label="Name" placeholder="Enter Name"
                            [control]="$any(contractFormGroup).controls['name']" [starIcon]="'*'"></app-input-filed>
                        <div *ngIf="contractFormGroup.controls?.['name']?.invalid &&
                             (contractFormGroup.controls?.['name']?.dirty ||
                             contractFormGroup.controls?.['name']?.touched)">
                            <div *ngIf="contractFormGroup.controls?.['name']?.errors?.['required']">
                                <small class="text-danger">Name is required.</small>
                            </div>
                        </div>
                    </div>
                    <div class="offCanvas__orgName-inputField">
                        <app-input-filed label="Description" placeholder="Enter Description"
                            [control]="$any(contractFormGroup).controls['description']"></app-input-filed>
                    </div>
                    <p class="text-danger m-2" *ngIf="errorMessage">{{errorMessage}}</p>
                </div>
            </div>
        </form>
    </div>
    <div class="offCanvas__footer">
        <app-common-button (clickEvent)="onCloseIconClick(false)" buttonClass="smallbutton"
            buttonLabel="Cancel"></app-common-button>
        <app-common-button (clickEvent)="onSubmit()" [loadingIcon]="verifyLoader" buttonClass="smallFilledButton"
            buttonLabel="Submit" [disabled]="contractFormGroup.invalid"></app-common-button>
    </div>
</div>