import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})

export class ConfirmationPopupComponent {
  @Input() message        : any;
  @Input() header         : any;
  @Input() lableName      : any;
  @Input() lableCancel    : any;
  @Input() isConfirmationMessage : boolean = false;
  @Input() isConfirmationMessageRequired : boolean = false;
  @Output() newFolderName : EventEmitter<any> = new EventEmitter();

  reason : FormControl<any> = new FormControl('');

  constructor(
    public activeModal: NgbActiveModal) {}

  successClose(){
    if(this.isConfirmationMessage){
      this.activeModal.close({
        reason : this.reason.value,
        isClose :true
      })
    }else{
      this.activeModal.close(true)
    }
  }

}
