import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { AuthService } from '../auth/auth.service';
import { StorageKeys } from '../../share/enums/storage.enum';
import { environment } from '../../../environments/environment';
// @ts-ignore
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.js';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';
import { faSignOut } from "@fortawesome/free-solid-svg-icons/faSignOut";
import { Router } from "@angular/router";
import { RouteConstants } from 'src/app/share/constants/routes.constants';
import { SdexAdminService } from 'src/app/share/services/sdex/sdex-admin.service';
import { userSelfDetails } from '../../user-storage';
import { adminSideBarList, companySideBarList } from './admin';
import { CommonService } from 'src/app/share/services/common/common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() isExpanded       : boolean = false;
  @Output() toggleSidebar   : EventEmitter<boolean> = new EventEmitter<boolean>();
  enrollmentType            : any;
  selectedCompany           : any;
  bootstrap                 : any;
  userDetailsData           : any;
  profilePic                : any;
  user                      : any = {};
  selectedMenuItem          : number = 1;
  name                      : string
  verifyLoader              : boolean = false;
  isSubMenuActive           : boolean = false;
  showTooltip               : boolean = false;
  hideText                  : boolean = false;
  buttonText                : string = '<';
  sideBarList               : any[] = [];
  userCircleIcon            = faUserCircle;
  protected readonly faSignOut = faSignOut;

  constructor(
    private storageService: StorageService,
    public authService: AuthService,
    private userSettingService: UserSettingService,
    private router: Router,
    public sdexAdminService: SdexAdminService,
    private commonService: CommonService
  ) { }

  loadSideBar() {
    if (this.userDetailsData?.enrollmentType === 'Admin') {
      this.sideBarList = adminSideBarList(environment?.envType);
    } else {
      this.sideBarList = companySideBarList(environment?.envType, this.authService.isCompanyAdmin(), this.userDetailsData?.primary, this.userDetailsData, this.authService);
    }
  }

  ngOnInit() {
    this.commonService.getReloadSidebar()?.subscribe(value => {
      if(value){
        this.getSelfDetails();
      }
    })
    this.getSelfDetails();
    Array.from(
      document.querySelectorAll('button[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));

    this.userSettingService.getProfilePic().subscribe((res: any) => {
      this.profilePic = res;
    })
    this.userSettingService.getUpdatedUserName().subscribe((res: any) => {
      this.userDetailsData.name = res;
    });
  }

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);

  toggleSubMenu() {
    this.isSubMenuActive = !this.isSubMenuActive;
  }

  isMenuItemActive(index: number): boolean {
    return this.selectedMenuItem === index;
  }

  getSelfDetails() {
    userSelfDetails(this.authService, this.sdexAdminService, this.storageService, environment?.envType, (selfDetails: any) => {
      if (selfDetails) {
        this.userDetailsData = selfDetails;
        this.profilePic = this.userDetailsData?.profileImage;
      }
      this.user = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
      this.selectedCompany = this.user?.company;
      this.loadSideBar();
    });
  }

  logout() {
    this.verifyLoader = true;
    this.authService.logout().subscribe(res => {
      if (res.data) {
       localStorage.clear();
       this.verifyLoader = false;
       this.router.navigate([RouteConstants.Login]);
      }
    })
  }

  toggleTextVisibility() {
    this.hideText = !this.hideText;
    this.buttonText = this.hideText ? '>' : '<';
    this.showTooltip = !this.showTooltip;
  }

  hideListText() {
    this.hideText = true
    this.buttonText = '>'
    this.showTooltip = true
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  onCompanyChange(event: any) {
    if (event?.target?.value) {
      this.user = this.storageService.getDataFromLocalStorage(
        StorageKeys.SELF_DETAILS
      );
      this.selectedCompany = event.target.value;
      if (this.user) {
        this.user.company = this.selectedCompany;
        for(let assigned of (this.user?.assignedTo || [])){
          if ((assigned.company._id === this.user.company) && this.user.company) {
            this.user.presentRoles = assigned.roles || [];
          }
        }
        this.storageService.setDataToLocalStorage(
          StorageKeys.PRESENT_COMPANY,
          this.selectedCompany
        );
        this.storageService.setDataToLocalStorage(
          StorageKeys.SELF_DETAILS,
          this.user
        );
        this.router.navigate(['/user-setting/user-profile']);
        setTimeout(()=>{
          location.reload();  
        })
      }
    }
  }
  scrollTo() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
}
