import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-input-filed',
  templateUrl: './input-filed.component.html',
  styleUrls: ['./input-filed.component.scss'],
})

export class InputFiledComponent implements OnInit {
  @Input() label         : string;
  @Input() labelClass    : string;
  @Input() placeholder   : string;
  @Input() value         : string;
  @Input() type          : string;
  @Input() class         : string;
  @Input() minlength     : string;
  @Input() maxDate       : string;
  @Input() maxlength     : number | string;
  @Input() maxDigits     : number;
  @Input() max           : number;
  @Input() min           : number;
  @Input() pattern       : any;
  @Input() starIcon      : any;
  @Input() control       : FormControl;
  @Input() toUpperCase   : boolean;
  @Input() readonly      : boolean;
  @Input() allowedType   : string
  @Input() autocomplete  : string
  @Output() inputEvent   : EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.control = new FormControl('');
  }

  onChange(event: any) {
    if (this.maxDigits) {
      let controlValue = this.allowedType === 'number' ? String(this.control.value) : this.control.value;   
      let digits = controlValue?.replace(/\D/g, '');
      if (digits?.length > this.maxDigits) {
        digits = digits.slice(0, this.maxDigits);
        this.control.setValue(this.allowedType === 'number' ? Number(digits) : digits);
      }
    }
  }

  onKeyDown(event: KeyboardEvent, val: any) {
    if (this.allowedType === 'chars') {
      const key = event.key;
      const specialCharacterAndNumberRegex = /[!@#$%^&*()+{}[\]:;<>,.?~\\/\d-]/
      if (specialCharacterAndNumberRegex.test(key)) {
        event.preventDefault();
      }
    }
    if (this.allowedType === 'number') {
      if (event.key == 'Backspace') return;
      const key = event;
      const regex = /\d|\./;
      if (!regex.test(event.key)) {
        if (key.preventDefault) key.preventDefault();
      }
    }
  }

  ngOnInit(): void {
    this.control?.valueChanges.pipe(debounceTime(500))
      .pipe(distinctUntilChanged()).subscribe((res: any) => {
        if (this.control.value) {
          const trimmedValue = this.allowedType === 'number' ? String(this.control.value) : this.control.value;
          const processedValue = this.toUpperCase ? trimmedValue.replace(/\s+/g, ' ').toUpperCase() : trimmedValue.replace(/\s+/g, ' ');
          const finalValue = processedValue.replace(/^\s+/, '');
          this.control.setValue(this.allowedType === 'number' ? Number(finalValue) : finalValue, { emitEvent: false });
        }
      })
  }

  getInputValue(event: any){
    this.inputEvent.emit(event);
  }
}
