<div class="offCanvas__container">
  <form [formGroup]="companySettingsFormGroup" (ngSubmit)="saveProfile()">
    <div class="offCanvas__contentWrapper">
      <div class="offCanvas__headerWrapper">
        <div class="offCanvas__header">
          <div class="offCanvas__title">Edit Address</div>
          <img (click)="onCloseIconClick()" class="offCanvas__crossIcon"
            src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon" />
        </div>
      </div>
      <div class="offcanvas__content">
        <div class="offcanvas__orgDetails">
          <div *ngIf="!isLRAddress" class="organisationalDetails__imageContainer">
            <div class="profile-container">
              <div class="profile-image" [ngClass]="{'profileIcon-indams': envType === 'DAMS'}" (click)="file.click()">
                <h4 *ngIf="envType=='DAMS'"> Profile</h4>
                <ng-container *ngIf="organisationDetailsData?.profileImage">
                 <img src="{{ organisationDetailsData?.profileImage }}" alt="Image"
                   [ngClass]="{'profileImage-indams': envType === 'DAMS','profileImage-insdex': envType !== 'DAMS'}" />
                 <span class="profileIcon"><fa-icon [icon]="faCamera"></fa-icon></span>
                </ng-container>
                <ng-container *ngIf="!organisationDetailsData?.profileImage">
                  <fa-icon [icon]="userCircleIcon" style="color: gray; font-size: 50px">
                  </fa-icon>
                  <span><fa-icon [icon]="faCamera"></fa-icon></span>
                </ng-container>
              </div>
              <div class="profile-change">
                <input style="display: none" #file type="file" accept="image/*,jpg,png" (change)="upload($event)" />
              </div>
            </div>
            <!-- fevicon -->
            <div *ngIf="envType=='DAMS'" class="profile-container">
              <div class="profile-image" [ngClass]="{'profileIcon-indams': envType === 'DAMS'}" (click)="fileFavicon.click()">
                <h4>FavIcon</h4>
                <ng-container *ngIf="organisationDetailsData?.favIcon">
                  <img src="{{ organisationDetailsData?.favIcon }}" alt="Image" class="favIcon-indams"/>
                  <span class="profileIcon"><fa-icon [icon]="faCamera"></fa-icon></span>
                </ng-container>
                <ng-container *ngIf="!organisationDetailsData?.favIcon">
                  <fa-icon [icon]="userCircleIcon" style="color: gray; font-size: 50px">
                  </fa-icon>
                  <span><fa-icon [icon]="faCamera"></fa-icon></span>
                </ng-container>
              </div>
              <div class="profile-change">
                <input style="display: none" #fileFavicon type="file" accept="image/*,jpg,png"
                  (change)="uploadFavicon($event)" />
              </div>
            </div>
            <!-- fevicon -->
          </div>

          <small class="text-danger">
            {{errorMessage1}}
          </small>
          <div *ngIf="envType=='DAMS'" class="custom-input-dams">
            <label>Company name <span style="color:#de7141;">*</span></label>
            <input type="text"  [formControl]="companyName">
          </div>
          <app-input-filed label="Address Line 1" [control]="$any(companySettingsFormGroup).controls['line1']"
            placeholder="Address Line 1" [value]="address?.line1" [starIcon]="'*'"></app-input-filed>
          <small class="text-danger"
            *ngIf="companySettingsFormGroup.get('line1')?.invalid && companySettingsFormGroup.get('line1')?.touched">
            Address Line 1 is required.
          </small>

          <app-input-filed label="Address Line 2" [control]="$any(companySettingsFormGroup).controls['line2']"
            placeholder="Address Line 2" [value]="address?.line2"></app-input-filed>

          <app-input-filed label="Locality" [control]="$any(companySettingsFormGroup).controls['locality']"
            placeholder="Locality" [value]="address?.locality"></app-input-filed>

          <app-input-filed label="Landmark" [control]="$any(companySettingsFormGroup).controls['landmark']"
            placeholder="Landmark" [value]="address?.landmark"></app-input-filed>

          <app-input-filed label="City" [control]="$any(companySettingsFormGroup).controls['city']" placeholder="City"
            [value]="address?.city" [starIcon]="'*'"></app-input-filed>
          <small class="text-danger"
            *ngIf="companySettingsFormGroup.get('city')?.invalid && companySettingsFormGroup.get('city')?.touched">
            City is required.
          </small>

          <app-input-filed label="State" [control]="$any(companySettingsFormGroup).controls['state']"
            placeholder="State" [value]="address?.state" [allowedType]="'chars'" [starIcon]="'*'"></app-input-filed>
          <small class="text-danger"
            *ngIf="companySettingsFormGroup.get('state')?.invalid && companySettingsFormGroup.get('state')?.touched">
            State is required.
          </small>

          <app-input-filed *ngIf="isLRAddress" label="Country"
            [control]="$any(companySettingsFormGroup).controls['country']" placeholder="Country"
            [value]="address?.country"></app-input-filed>

          <app-input-filed label="Pincode" [control]="$any(companySettingsFormGroup).controls['pincode']"
            placeholder="Pincode" [value]="address?.pincode" [maxDigits]="6" (input)="pincodeValidation()" type="number"
            [starIcon]="'*'"></app-input-filed>
          <small class="text-danger"
            *ngIf="companySettingsFormGroup.get('pincode')?.invalid && companySettingsFormGroup.get('pincode')?.touched">
            Pincode is required.
          </small>

        </div>
      </div>
    </div>

    <div class="offCanvas__footer">
      <app-common-button type="button" (click)="onCloseIconClick()" buttonClass="smallbutton"
        buttonLabel="Cancel"></app-common-button>
      <div>
        <app-common-button type="submit" buttonClass="smallFilledButton" [loadingIcon]="verifyLoader" buttonLabel="Save"
          [disabled]="companySettingsFormGroup.invalid"></app-common-button>
      </div>
    </div>
  </form>
</div>