import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BarController, BarElement, CategoryScale, Decimation, Filler, Legend, Title, Tooltip } from 'chart.js';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss'
})
export class BarChartComponent implements OnChanges {
  @Input() data: any;
  @Input() isBarChartLoder: any;

  constructor(private datePipe: DatePipe) {
    Chart.register(BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip);
  }
  public chart: Chart;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      if (this.data.some((x: any) => x.FileSize > 0)) {
        this.createBarChart(this.data);
      } else {
        let chartExists = Chart.getChart("canvas");
        if (chartExists) {
          chartExists.destroy();
        }
        this.data = [];
      }
    }
  }

  getDataSet(data: any): any {
    let labels: any = [];
    let storageValues: any = [];
    data.forEach((x: any) => {
      labels.push(x.label)
      storageValues.push(x.FileSize);
    });

    let dataSet: any = {
      labels: labels,
      datasets: [{
        type: 'bar',
        label: 'Storage',
        data: storageValues,
        fill: false,
        borderColor: this.getRandomColor(),
        backgroundColor: this.getRandomColor()
      }]
    };

    let chartExists = Chart.getChart("canvas");
    if (chartExists) {
      chartExists.destroy();
    }
    return dataSet;
  }

  createBarChart(data: any) {
    let dataSet = this.getDataSet(data);
    this.chart = new Chart("canvas", {
      type: "bar",
      data: dataSet,
      options: {
        scales: {
          y: {
            ticks: {
              callback: (size: any, index, ticks) => {
                  let modifiedSize : any = this.converStorage(size);
                  let filteredArray = ticks.filter((tick: any)=> {return tick.label === modifiedSize.value});
                  if(filteredArray.length && modifiedSize !== '' ){
                    modifiedSize.value = modifiedSize.orgValue.toFixed(1) + modifiedSize.type; 
                  }
                  return modifiedSize.value;
              }
            }
          },
        },
        plugins: {
          datalabels:{
              display: false
          },
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 12
            }
          },
          tooltip: {
            callbacks: {
              label:  (context) => {
               return this.labelValues(context.parsed.y, context.dataset.label);
              }
            }
          }
        }
      }
    });
  }

  converStorage(size: any) {
    if (size) {
      if (size < 1024) {
        return {
          value :  0 + ' B',
          orgValue : 0,
          type : 'B'
        }
      } else if (size < 1024 * 1024) {
        return {
          value :  Math.round(size / 1024) + ' KB',
          orgValue : size / 1024,
          type : 'KB'
        }
      } else if (size < 1024 * 1024 * 1024) {
        return {
          value :  Math.round(size / (1024 * 1024)) + ' MB',
          orgValue : size / (1024 * 1024),
          type : ' MB'
        }
      } else {
        return {
          value : Math.round(size / (1024 * 1024 * 1024)) + ' GB',
          orgValue : size / (1024 * 1024 * 1024),
          type : 'GB'
        }
      }
    } else {
      return '';
    }
  }

  labelValues(size: any, label: any){
    if (size) {
      if (size < 1024) {
        return label + ': ' + 0 + ' B';
      } else if (size < 1024 * 1024) {
        return label + ': ' + +(size / 1024).toFixed(2) + ' KB';
      } else if (size < 1024 * 1024 * 1024) {
        return label + ': ' + +(size / (1024 * 1024)).toFixed(2) + ' MB';
      } else {
        return label + ': ' + +(size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
      }
    } else {
      return label + ': ' + '0 MB';
    }
  }

  getRandomColor() {
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    const random = array[0] / 2 ** 32;
    return '#' + Math.floor(random * 16777215).toString(16);
  }
}
