<div>
    <div class="company-head">
        <div class="lookupDataList __section">
            <div class="lookupDatalist__management listPage__Description">
                <app-list-page-header headerTitle="Network"
                    headerDescription="Manage all your Network data"></app-list-page-header>
            </div>
        </div>
        <div class="d-flex">
            <div class="search_feild">
                <app-input-filed placeholder="search with company ...."
                    [control]="$any(companySearchValue)"></app-input-filed>
            </div>
            <app-common-button appAccessControl allowedRoles='guest-company.create' buttonClass="regularButton" buttonLabel="Add New"
                (clickEvent)="openOverlay('')"></app-common-button>
        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist"></ul>
    <table class="custom-table-list" aria-label="cred_table">
        <thead>
            <tr class="custom-table-list-headings">
                <th class="custom-col-width-20">Company Name</th>
                <th class="custom-col-width-20">Company Short Name</th>
                <th class="custom-col-width-20">Party Name</th>
                <th class="custom-col-width-20">Email</th>
                <th class="custom-col-width-20">Actions</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let contact of contactList let i = index">
                <tr class="custom-table-list-row-main">
                    <td class="custom-col-width-20">{{contact?.name}}</td>
                    <td class="custom-col-width-20">{{contact?.shortName}}</td>
                    <td class="custom-col-width-20">{{contact?.userDetails[0]?.name}}</td>
                    <td class="custom-col-width-20">{{contact?.userDetails[0]?.emailAddress}}</td>
                    <td class="custom-col-width-20">
                        <span class="d-flex justify-content-start">
                            <span (click)="openOverlay(contact)" data-bs-toggle="tooltip" ngbTooltip="Edit"
                                [placement]="'left'" appAccessControl allowedRoles='guest-company.edit' >
                                <div tooltipClass="custom-tooltip" class="btn btn-primary btn-sm me-2"
                                    style="background-color: #de7141; border-color: #de7141;">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </div>
                            </span>
                            <span *ngIf="contact?.userDetails.length" (click)="toggleAdditionalRow(i,contact)" data-bs-toggle="tooltip" ngbTooltip="User Details"
                                [placement]="'right'">
                                <div class="btn btn-primary btn-sm me-2 fa-icon-class"
                                    style="background-color: #de7141; border-color: #de7141;">
                                    <fa-icon
                                        [icon]="selectedContactIndex === i ? faArrowAltCircleUp : faArrowAltCircleDown"></fa-icon>
                                </div>
                            </span>
                        </span>
                    </td>
                </tr>
                <ng-container *ngIf="selectedContactIndex === i && selectedContact?.userDetails.length">
                    <table class="custom-table-list" aria-label="cred_table">
                        <thead>
                            <tr class="custom-table-list-headings user-row">
                                <th class="custom-col-width-50">Name</th>
                                <th class="custom-col-width-50">Email</th>
                                <th class="custom-col-width-50">Designation</th>
                                <th class="custom-col-width-50">Authorized Signatory/ Co-Auther</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="custom-table-list-row-main user-row"
                                *ngFor="let user of selectedContact.userDetails let i = index">
                                <td class="custom-col-width-50">{{user?.name}}</td>
                                <td class="custom-col-width-50">{{user?.emailAddress}}</td>
                                <td class="custom-col-width-50">{{user?.designation}}</td>
                                <td class="custom-col-width-50">
                                    {{user.permissions.join(',')}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </ng-container>
            <tr class="custom-table-list-row-main" *ngIf="isLoadRecords">
                <td class="custom-col-width-100 text-danger d-flex justify-content-center">
                    <app-common-icon [loadingIcon]="isLoadRecords"></app-common-icon>
                </td>
            </tr>
            <tr class="custom-table-list-row-main" *ngIf="!isLoadRecords && contactList.length === 0">
                <td class="custom-col-width-100 text-danger d-flex justify-content-center">
                    No Companies Found!
                </td>
            </tr>
        </tbody>
    </table>
    <app-paginator [totalCount]="contactsCount" (selectedPage)="handlePaginationPageChange($event)">
    </app-paginator>
    <div *ngIf="isOverlayOpen">
        <div class="overlay {{isOverlayOpen ? 'show' : ''}} ">
        </div>
        <div class="off-canvas width-640 {{isOverlayOpen ? 'show' : ''}}">
            <div class="off-canvas-content">
                <app-edit-contacts [contactItem]="contactItem" (closePanel)="closeOverlay($event)"></app-edit-contacts>
            </div>
        </div>
    </div>
</div>
<div class="toaster_message" *ngIf="isToster">
    <app-toast [title]="message" (closeToaster)="closeToster($event)"></app-toast>
</div>