<table class="custom-table-list" aria-label="cred_table">
  <thead>
    <tr class="custom-table-list-headings">
      <th class="custom-col-width-50">Module Name </th>
      <th class="custom-col-width-30"></th>
      <th class="custom-col-width-20 d-flex justify-content-center ">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr class="custom-table-list-row-main" *ngFor="let value of moduleList">
      <td class="custom-col-width-50">{{value?.module}}</td>
      <td class="custom-col-width-30"></td>
      <td class="custom-col-width-20">
        <span class="d-flex justify-content-center">
          <span data-bs-toggle="tooltip" ngbTooltip="Edit" [placement]="'left'">
            <div tooltipClass="custom-tooltip" (click)="editModuleList(value)" class="btn btn-primary btn-sm me-2"
              style="background-color: #de7141; border-color: #de7141;">
              <fa-icon [icon]="faEdit"></fa-icon>
            </div>
          </span>
        </span>
      </td>
    </tr>
  </tbody>
</table>
<div class="overlay" *ngIf="isModule" [class.show]="isModule"></div>
<div class="off-canvas width-450" *ngIf="isModule" [class.show]="isModule">
  <div class="off-canvas-content">
    <app-add-module-type [moduleItem]="moduleItem" (closePanel)="onCloseClick($event)"></app-add-module-type>
  </div>
</div>