<div class="modal-content main-div">
    <div class="modal-header ">
        <h4 class="modal-title" id="modal-basic-title">
            Select Esign Position
        </h4>
        <div class="d-flex justify-content-start gap-2">
            <div (click)="activeModal.close(false)" class="close"><img
                    src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon"></div>
        </div>
    </div>
    <div class="modal-body body-div">
        <div>
            <div *ngIf="isPdfLoading">
                <div class="custom-col-width-100 text-danger d-flex justify-content-center">
                    <app-common-icon [loadingIcon]="isPdfLoading"></app-common-icon>
                </div>
            </div>
            <div class="container-div">
                <div>
                    <div class="user-section card" *ngIf="isShowUsers">
                        <div class="card-header">
                            <span *ngIf="isShowUsers">Select User</span>
                        </div>
                        <div class="card-body">
                            <div class=" userDropdown select__dropdown" *ngIf="isShowUsers">
                                <div class="select__dropdownLabel">
                                    Users
                                </div>
                                <select class="select__dropdownOptions" [(ngModel)]="selectedUser"
                                    (change)="handleSelectUser($event)">
                                    <option *ngFor="let user of usersList let index = index"
                                        [ngValue]="user?.emailAddress">
                                        {{ user?.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="user-section card" *ngIf="usersList?.length > 0">
                        <div class="card-header">
                            <div class="user_position_heading">
                                <p>Sign Positions</p>
                                <fa-icon (click)="addNewSignPosition()" [icon]="faPlusCircle"
                                    class="custom_edit_faicon"></fa-icon>
                            </div>
                        </div>
                        <div class="card-body">
                            <div>
                                <div class="position_div"
                                    *ngFor="let sign of usersList[selectedUserIndex]?.signPositions let index = index"
                                    [class.activePosition]="selectedSignPositionIndex === index"
                                    (click)="handlePositionSelection(index)">
                                    <div class="position_heading">
                                        <p>Configure Position - {{index + 1}}</p>
                                        <fa-icon *ngIf="usersList[selectedUserIndex]?.signPositions?.length > 1"
                                            (click)="removeSignPosition(index)" [icon]="faTrash"
                                            class="custom_edit_faicon"></fa-icon>
                                    </div>
                                    <div class="position_body"  (click)="handlePositionSelection(index)">
                                        <div>
                                            <app-checkbox checkboxStatus="{{sign.isCustomPosition}}"
                                                checkBoxName="Custom position"
                                                (checked)="handleCustomPostion($event, sign)"></app-checkbox>
                                        </div>
                                        <div class="select__dropdown" *ngIf="!sign.isCustomPosition">
                                            <div class="select__dropdownLabel">
                                                Page Type
                                            </div>
                                            <select class="select__dropdownOptions" [(ngModel)]="sign.pageType"
                                               >
                                                <option *ngFor="let item of pageTypes" [ngValue]="item?.value">
                                                    {{ item?.label }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="select__dropdown" *ngIf="!sign.isCustomPosition">
                                            <div class="select__dropdownLabel">
                                                Signature Position
                                            </div>
                                            <select class="select__dropdownOptions"
                                                [(ngModel)]="sign.signaturePosition"
                                               >
                                                <option *ngFor="let item of signaturePositionTypes"
                                                    [ngValue]="item?.value">
                                                    {{ item?.label }}
                                                </option>
                                            </select>
                                        </div>
                                        <p
                                            *ngIf="!sign?.pageNumber && !sign?.left && !sign?.top && sign.isCustomPosition">
                                            Drag signature box to set sign position
                                        </p>
                                        <div *ngIf="sign.isCustomPosition">
                                            <div class="position_title" *ngIf="sign?.pageNumber">Page Number</div>
                                            <div class="position_value" *ngIf="sign?.pageNumber">{{sign?.pageNumber}}
                                            </div>
                                            <div class="d-flex justify-content-start gap-2">
                                                <div>
                                                    <div class="position_title" *ngIf="sign?.left">Left</div>
                                                    <div class="position_value" *ngIf="sign?.left">{{sign?.left}}</div>
                                                </div>
                                                <div>
                                                    <div class="position_title" *ngIf="sign?.top">Top</div>
                                                    <div class="position_value" *ngIf="sign?.top">{{sign?.top}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="signBoxDiv">
                    <div class="d-flex justify-content-end mb-2" [ngClass]="handleEsignStyleClass() ? 'display-block' : 'display-none'">
                        <div class="esign-box" #esignBoxElement>
                            Signature
                        </div>
                    </div>


                    <div class="thumbnaildiv" [class.thumbnail-position]="handleEsignStyleClass()">
                        <div *ngFor="let item of thumbnail" id="page{{item.page}}"
                            (click)="handleThumbnailClick(item.page)">
                            <div class="thumbnail">
                                <img [class.selected]="page.value === item.page" alt="thumbnailImage" class="thumbnailImage"
                                    src="{{item.url}}">
                            </div>
                            <div class="pagenumberDiv">
                                <p class="pagenumber">{{item.page}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="pdf-container" *ngIf="pdfSrc">
                        <pdf-viewer [src]="pdfSrc" [zoom]="0.75" [page]="page.value" [show-all]="false"
                            [render-text]="true" style="display: block;"
                            (after-load-complete)="callBackFn($event)"></pdf-viewer>
                        <div style="visibility: hidden;" *ngIf="thumbnailPdfview">
                            <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
                                (page-rendered)="pageRendered($event)" style="width: 100px; height: 100px"></pdf-viewer>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer row">
        <div class="footer-actions col-md-6">
            <div class="page-actions">
                <div class="page-icon">
                    <span data-bs-toggle="tooltip" ngbTooltip="Previous Page" [placement]="'left'">
                        <div (click)="decreasePageNumber()" tooltipClass="custom-tooltip"
                            class="btn btn-primary btn-sm b-btn">
                            <app-common-icon [faIcon]="faCaretLeft"></app-common-icon>
                        </div>
                    </span>
                </div>
                <div>
                    <app-input-filed placeholder="Enter Page Number" [type]="'number'" [value]="page.value"
                        [control]="page" allowedType="number" [min]="1" [max]="totalPages"></app-input-filed>
                </div>
                <div class="page-icon">
                    <span data-bs-toggle="tooltip" ngbTooltip="Next Page" [placement]="'right'">
                        <div (click)="increasePageNumber()" tooltipClass="custom-tooltip"
                            class="btn btn-primary btn-sm me-2 b-btn">
                            <app-common-icon [faIcon]="faCaretRight"></app-common-icon>
                        </div>
                    </span>
                </div>
            </div>
            <div class="d-flex">
                <app-common-button class="m-1" buttonClass="smallbutton" [buttonLabel]="'Cancel'"
                    (click)="activeModal.close(false)">
                </app-common-button>
                <app-common-button class="m-1" buttonClass="smallFilledButton" [buttonLabel]="'Submit'"
                    (click)="successClose()">
                </app-common-button>
            </div>
        </div>
    </div>
</div>