import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganisationStructureService {

  constructor(
    private httpClient : HttpClient
  ) { }

  getCompanyStructure(params : any):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/company-structure/tree-view`,{params : params})
  }

  saveSubComany(payload : any):Observable<any>{
    return this.httpClient.post(`${environment.API_URL}/v1/company/sub-company/create`,payload);
  }

  getOrgnisationDetails(orgId : string):Observable<any>{
    return this.httpClient.get(`${environment.API_URL}/v1/company/sub-company-details/${orgId}`)
  }

  updateSubCompanyDetails(orgId : string, payload : any):Observable<any>{
    return this.httpClient.put(`${environment.API_URL}/v1/company/sub-company-details/${orgId}`,payload)
  }

  updateZoneCompanyDetails(orgId : string, payload : any):Observable<any>{
    return this.httpClient.put(`${environment.API_URL}/v1/company/self-company-details`,payload)
  }

  updateRootCompanyDetails(orgId : string, payload : any):Observable<any>{
    return this.httpClient.put(`${environment.API_URL}/v1/company/detail/${orgId}`,payload)
  }

  addSubCompanyUser(payload : any):Observable<any>{
    return this.httpClient.post(`${environment.API_URL}/v1/user/add/sub-company-user?envType=${environment.envType}`,payload)
  }

  addCompanyUser(payload : any):Observable<any>{
    return this.httpClient.post(`${environment.API_URL}/v1/user`,payload)
  }

  searchOrganisation(searchText: string): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/v1/company-structure/tree-view-search?name=` + searchText)
  }

  companyLockUnlock(companyId: any, type: string): Observable<any> {
    let route = type == 'unLock' ? 'company-lock' : 'company-unlock';
    return this.httpClient.put(`${environment.API_URL}/v1/company/${companyId}/${route}`, '')
  }

}
