<div class="createAccount__bodySection"   [ngClass]="{'sdex-env': envType == appType.dams || envType == appType.clms}" >
<div class="createAccount__body" [ngClass]="{'sdex-env-dams_box': envType == appType.dams || envType == appType.clms}">
  <div class="createAccount__section"
    [ngClass]="{'sdex-env-dams': envType == appType.dams || envType == appType.clms, 'sdex-env-dams-form5':showForm5 && envType !== appType.sdex, 'sdex-env-sdex-form5':showForm5 && envType == appType.sdex}">
    <div  ngClass="{{(customUrldata?.logo?.length && envType!=='SDEX') ? 'accountLogin__logo_url' : envType =='SDEX'? 'accountLogin__logo':'accountLogin__logo_dc'}}">
    <div class="accountLogin__logo" *ngIf="envType=='SDEX' ">
      <img src="../../../assets/Images/login/headerLogo.svg" alt="headerLogo">
      <img src="../../../assets/Images/login/sdexLogo.svg" alt="sdexLogo">
    </div>
    <div ngClass="{{customUrldata?.logo?.length ? 'accountLogin__logo_url' : envType =='SDEX'? 'accountLogin__logo':'accountLogin__logo_dc'}}">
      <img ngClass="{{customUrldata?.logo?.length ? 'account_login_customlogo_url' : 'account_login_customlogo'}}"
        *ngIf="envType!=='SDEX' && customUrldata?.logo?.length" [src]="customUrldata.logo" alt="sdex">
        <div>
        <img ngClass="{{customUrldata?.logo?.length ? 'account_login_customlogo_url' : 'account_login_customlogo'}}"
            *ngIf="envType!=='SDEX' &&(!customUrldata?.logo?.length)" [src]="'../../../assets/Images/login/headerLogo.svg'" alt="sdex">
            <img *ngIf="envType!=='SDEX'" src="../../../assets/Images/login/sdexLogo.svg" alt="sdex">
          </div>
      <h3 *ngIf="envType !=='SDEX' && customUrldata?.companyName" class="accountLogin__logo_dams">
        {{customUrldata?.companyName.toUpperCase()}} </h3>
    </div>
    </div>
    <form *ngIf="showForm4" (ngSubmit)="verifySecondaryUser()" name="registerForm" [formGroup]="secondaryForm">
      <div class="createAccount__signIn">
        <div class="createAccount__setup">Setup your Account</div>
        <div class="createAccount__inputWrapper1 pt-4">
          <div class="createAccount__inputSection">
            <div class="createAccount__inputLabel">
              Enter a registered email
            </div>
            <app-input-filed placeholder="Email" [control]="$any(secondaryForm).controls['emailAddress']"
              id="emailAddress" (inputEvent)="getInputValue($event)" required>
            </app-input-filed>
            <div class="error-validation" *ngIf="
                secondaryForm.get('emailAddress')?.hasError('pattern') &&
                !secondaryForm.get('emailAddress')?.hasError('required')
              ">
              <small class="text-danger">Please enter a valid email address</small>
            </div>
            <div class="mt-2 text-center text-danger error-msg-wd">
              {{ errorMsg }}
            </div>
          </div>
          
        </div>
        <div class="createAccount__error">
          <img src="../../../assets/Images/login/error.svg" alt="error"/>
          <div class="createAccount__errorText">
            You will receive a mail with an OTP to verify your account
          </div>
        </div>
      </div>
      <div class="createAccount__button">
        <app-common-button buttonClass="smallFilledButton" [buttonLabel]="'Verify Email'"
        custButtonClass="custButtonClass" disableButtonClass="largeButtonDisable" [loadingIcon]="verifyLoader" [typeBtn]="true" [disabled]="secondaryForm.invalid || verifyLoader">
        </app-common-button>
      </div>
      <div class="accountLogin__signUp">
        <div class="accountLogin__signupText">Already have an account?</div>
        <a style="text-decoration: none" [routerLink]="['/home']">
          <div class="accountLogin__passwordText">Sign In</div>
        </a>
      </div>
    </form>

    <form *ngIf="showForm5" #form2="ngForm" name="registerForm2" [formGroup]="registerForm5" class="p-3">
      <div class="createAccount__section">
        <div class="createAccount__signIn">
          <div class="createAccount__setup">Setup Password</div>
          <div class="createAccount__addCredintials">
            Verify OTP and create a password
          </div>
          <div class="createAccount__inputWrapper">
            <div class="createAccount__inputSection">
              <div class="createAccount__inputLabel">Enter an OTP</div>
              <div class="createAccount__inputWrapper">
                <input class="createAccount__input" placeholder="******" formControlName="otp" id="password"
                  type="number" required />
                <button [ngClass]="disabled ?'disableBtn':'createAccount__verifyButton'" [disabled]="disabled"
                  (click)="resendOTP()">Resend OTP</button>
              </div>
              <div *ngIf="
                  !registerForm5.controls['otp'].valid &&
                  registerForm5.get('otp')?.touched
                ">
                <small class="text-danger">OTP is required</small>
              </div>
            </div>
            
            <!-- new -->
            <div class="createAccount__inputSection">
              <div class="createAccount__inputLabel">Create a password</div>
              <div class="usersFilter__branchesField">
                <div class="usersFilter__branchWrappar">
                  <input class="usersFilter__input" style="
                      border: none;
                      outline: none !important;
                      box-shadow: none !important;
                      border-color: #000 !important;
                      width: 336px;
                    " placeholder="***********" formControlName="password" class="" id="password"
                    oncopy = "return false"
                    onpaste =" return false"
                    type="{{ hide2 ? 'password' : 'text' }}" required />

                  <fa-icon class="usersFilter__searchIcon" [icon]="hide2 ? faEye : faEyeSlash" (click)="hide2 = !hide2"
                    [attr.aria-label]="'Hide2 Password'" [attr.aria-pressed]="hide2"></fa-icon>
                </div>

                <div class="invalid-feedback" *ngIf="
                    !registerForm5.controls['password']?.valid &&
                    registerForm5.controls['password']?.touched
                  ">
                  <small class="text-danger">Password is required</small>
                </div>
              </div>
              <div class="createAccount__error" *ngIf="registerForm5.get('password')?.hasError('pattern')"
                style="max-width:400px;margin-left: 0px;">

                <img src="../../../assets/Images/login/error.svg" alt="error"/>
                <small class="text-muted">Hint- Minimum of 8 characters with a uppercase, lowercase,
                  special character and a number required.</small>
              </div>
            </div>
            <!-- new -->
            <div class="createAccount__inputSection">
              <div class="createAccount__inputLabel">Confirm password</div>
              <div class="usersFilter__branchesField">
                <div class="usersFilter__branchWrappar">
                  <input class="usersFilter__input" style="
                      border: none;
                      outline: none !important;
                      box-shadow: none !important;
                      border-color: #000 !important;
                      width: 336px;" 
                    oncopy = "return false"
                    onpaste =" return false"
                     id="repassword" type="{{ hide ? 'password' : 'text' }}" placeholder="***********"
                    formControlName="repassword" class="" [type]="hide ? 'password' : 'text'" required />
                  <fa-icon class="usersFilter__searchIcon" [icon]="hide ? faEye : faEyeSlash" (click)="hide = !hide"
                    [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide"></fa-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="createAccount__error createAccount__addCredintials error-validation" style="margin-bottom: 0px;" *ngIf="
              registerForm5.get('password')?.value !==
                registerForm5.get('repassword')?.value &&
              registerForm5.get('repassword')?.touched
            ">
            Passwords do not match.
          </div>
          <div class="createAccount__error createAccount__addCredintials error-validation" *ngIf="errMsg">
            {{ errMsg }}
          </div>
        </div>
        <div class="createAccount__button" style="margin-top: 24.09px;" >
          <button  [ngClass]="registerForm5.invalid ? 'buttonDisable' : 'createAccount__signInButton'"
            [disabled]="registerForm5.invalid" (click)="onVerify5()">
            <span *ngIf="!verifyLoader">Submit</span>
            <span *ngIf="verifyLoader">
              <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
            </span>
          </button>
        </div>
      </div>
      <div *ngIf="ifReg" class="button-row text-center verify-links">
        <p>Registered Already? <a [routerLink]="['/home']">Sign In</a></p>
      </div>
    </form>

    <form *ngIf="showForm3" name="congratsForm" class="p-3">
      <div>
        <div class="cong-reg text-center">
          <i class="fa-regular fa-check"></i>
          <h2>Congratulations!</h2>
          <p>Your registration has been Successfully completed.</p>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center text-center">
        <app-common-button [routerLink]="['/home']" buttonClass="smallbutton text-center"
          buttonLabel="Login"></app-common-button>
      </div>
    </form>
  </div>

  <app-logo *ngIf="envType=='SDEX'"></app-logo>

</div>
</div>