import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/home/auth/auth.service';
import { DashBoardRouteConst } from 'src/app/share/constants/routes.constants';
import { CommonService } from 'src/app/share/services/common/common.service';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';

@Component({
  selector: 'app-common-steps-card',
  templateUrl: './common-steps-card.component.html',
  styleUrls: ['./common-steps-card.component.scss']
})

export class CommonStepsCardComponent implements OnInit {
  @Input() cardData           : any;
  @Input() esignSelfLink      : any;
  @Input() CreatePackageLink  : any;
  @Output() openNextStep      : EventEmitter<void> = new EventEmitter<void>();
  enrollmentType              : any;
  isdisabled                  : any;
  isAdmin                     : boolean;
  isDisabled                  : boolean;


  constructor(
    private router         : Router,
    private storageService : StorageService,
    public authService     : AuthService,
    public commonService   : CommonService,
  ) { }

  ngOnInit(): void {
    const selfDetails = this.storageService.getDataFromLocalStorage('selfDetails');
    this.enrollmentType = selfDetails?.enrollmentType;
    this.isAdmin = !selfDetails?.isAdmin;
  }

  onClickEvent(data: any) {
   const routeData = DashBoardRouteConst.filter((x:any) => x.label === data);
   this.handleNavigate(routeData[0]?.label, routeData[0]?.route, routeData[0]?.open)
  }

  handleNavigate(data: any, url: any, open: boolean = false): void {
    if (data === DashBoardRouteConst[0].label) {
      this.openNextStep.emit();
    } else if (data === DashBoardRouteConst[1].label || data === DashBoardRouteConst[2].label) {
      if (this.enrollmentType !== 'Admin') {
        this.openNextStep.emit();
      }
    } else if (data === DashBoardRouteConst[9].label || data === DashBoardRouteConst[10].label) {
      if (this.enrollmentType !== 'Admin') {
        this.router.navigate([url]);
      }
    } else {
      this.router.navigate([url], open ? { queryParams: { open: 'open' } } : {});
    }
  }
}
