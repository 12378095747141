import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faCamera, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { APPTYPES } from 'src/app/share/constants/common-constants';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-company-settings',
  templateUrl: './update-company-settings.component.html',
  styleUrls: ['./update-company-settings.component.scss']
})
export class UpdateCompanySettingsComponent implements OnInit, OnChanges {

  @Input() address: any;
  @Input() addressInfo: any;
  @Input() organisationDetailsData: any;
  @Input() companyLogo: any;
  @Input() favIconLogo: any;
  @Input() isLRAddress: boolean;
  @Output() closePanel: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveCompanyProfile: EventEmitter<any> = new EventEmitter<any>();
  companySettingsFormGroup: FormGroup;
  profileImage: any;
  favIcon: any;
  errorMessage: any;
  errorMessage1: any;
  @Input() verifyLoader: boolean = false;
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;
  companyName: FormControl = new FormControl('', [Validators.required]);

  @ViewChild('file') file: ElementRef;
  @ViewChild('fileFavicon') fileFavicon: ElementRef;

  constructor(
    private fb: FormBuilder,
    private userSettingService: UserSettingService) { }

  ngOnInit(): void {
    if (!this.companySettingsFormGroup) {
      this.createCompanySettingsForm();
    }
    if (this.isLRAddress) {
      this.companySettingsFormGroup.addControl('country', new FormControl(this.addressInfo?.country));
    }
  }

  ngOnChanges(): void {
    if (!this.companySettingsFormGroup) {
      this.createCompanySettingsForm();
    }
    const previousThis = this;
    if (this.addressInfo) {
      setTimeout(function () {
        previousThis.setCompayAddress(previousThis.addressInfo);
      })
    }
  }

  createCompanySettingsForm() {
    this.companySettingsFormGroup = this.fb.group({
      line1: [this.addressInfo?.line1, [Validators.required]],
      line2: [this.addressInfo?.line2],
      locality: [this.addressInfo?.locality],
      landmark: [this.addressInfo?.landmark],
      city: [this.addressInfo?.city, [Validators.required]],
      state: [this.addressInfo?.state, [Validators.required]],
      pincode: [this.addressInfo?.pincode, [Validators.required]],
    })
    this.companyName.setValue(this.organisationDetailsData[0]?.value)
  }

  pincodeValidation() {
    const pincode = this.companySettingsFormGroup.value.pincode;
    if (pincode.charAt(0) == '0') {
      this.companySettingsFormGroup.patchValue({ pincode: pincode.substring(1) })
    } else {
      this.companySettingsFormGroup.patchValue({ pincode: pincode })
    }
  }


  setCompayAddress(value: any) {
    this.companySettingsFormGroup.patchValue({
      line1: value?.line1,
      line2: value?.line2,
      locality: value?.locality,
      landmark: value?.landmark,
      city: value?.city,
      state: value?.state,
      pincode: value?.pincode,
    })
  }

  upload(event: any) {
    if (event?.target?.files.length) {
      let files = event?.target?.files[0];
      let formData = new FormData();
      formData.append('file', files, files.name);
      if (files.size / 1024 <= 5000) {
        this.userSettingService.uploadFile(formData).subscribe({
          next: (res: any) => {
            this.profileImage = res.data[0].objectUrl;
            if (this.organisationDetailsData) {
              this.organisationDetailsData.profileImage = this.profileImage;
              this.errorMessage = res.message
                ? res.message
                : `Profile image uploaded successfully`;
            }
          },
          error: (error: any) => {
            this.errorMessage = `Failed to upload profile picture. Please try again`;
            this.errorMessage1 = '';
          }
        });
      } else {
        this.errorMessage1 = `Image size should be less than 5MB`;
      }

    }
  }

  //upload favIcon
  uploadFavicon(event: any) {
    if (event?.target?.files.length) {
      let filesFavicon = event?.target?.files[0];
      let formData = new FormData();
      formData.append('fileFavicon', filesFavicon, filesFavicon.name);
      if (filesFavicon.size / 1024 <= 5000) {
        this.userSettingService.uploadFile(formData).subscribe({
          next: (res: any) => {
            this.favIcon = res.data[0].objectUrl;
            if (this.organisationDetailsData) {
              this.organisationDetailsData.favIcon = this.favIcon;
              this.errorMessage = res.message
                ? res.message
                : `favicon  uploaded successfully`;
            }
          },
          error: (error: any) => {
            this.errorMessage = `Failed to upload favicon. Please try again`;
            this.errorMessage1 = '';
          }
        });
      } else {
        this.errorMessage1 = `Image size should be less than 5MB`;
      }

    }
  }

  saveProfile() {
    const payload = {
      address: this.companySettingsFormGroup.value,
      profileImage: this.profileImage,
      favIcon: this.favIcon,
      companyName: this.companyName?.value
    };
    document.body.classList.remove('panel-open');
    this.saveCompanyProfile.emit(payload);
  }


  onCloseIconClick() {
    this.closePanel.emit(false);
    this.organisationDetailsData.profileImage = this.companyLogo;
    this.organisationDetailsData.favIcon = this.favIconLogo;
    this.resetFileUploader()
    document.body.classList.remove('panel-open');
    this.errorMessage1 = "";
  }

  resetFileUploader() {
    this.file.nativeElement.value = null;
  }

  protected readonly faCamera = faCamera;
  protected readonly userCircleIcon = faUserCircle;

}
