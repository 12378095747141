<app-offcanvas-header paddingClass="paddingClass" header="{{isEdit ? 'Update' : 'Add'}} {{'Contact'}}"
  (closeClicked)="onCloseIconClick(false)"></app-offcanvas-header>
<div class="offCanvas__container">
  <div class="offCanvas__contentWrapper">
    <form class="mt-2" [formGroup]="editFormGroup">
      <div class="offcanvas__content">
        <div class="offcanvas__orgDetails">
          <div class="row">
            <div class="offCanvas__orgName-inputField col-md-6">
              <app-input-filed label="Company Name" placeholder="Enter Company Name"
                [control]="$any(editFormGroup)?.controls['name']" [starIcon]="'*'"></app-input-filed>
              <div *ngIf="editFormGroup.controls?.['name']?.invalid &&
                             (editFormGroup.controls?.['name']?.dirty ||
                             editFormGroup.controls?.['name']?.touched)">
                <div *ngIf="editFormGroup.controls?.['name']?.errors?.['required']">
                  <small class="text-danger">Company Name is required.</small>
                </div>
              </div>
            </div>
            <div class="offCanvas__orgName-inputField col-md-6">
              <app-input-filed label="Short Name" placeholder="Enter Short Name"
                [control]="$any(editFormGroup)?.controls['shortName']" [starIcon]="'*'"></app-input-filed>
              <div *ngIf="editFormGroup.controls?.['shortName']?.invalid &&
                             (editFormGroup.controls?.['shortName']?.dirty ||
                             editFormGroup.controls?.['shortName']?.touched)">
                <div *ngIf="editFormGroup.controls?.['shortName']?.errors?.['required']">
                  <small class="text-danger">Short Name is required.</small>
                </div>
              </div>
            </div>
          </div>
          <div formGroupName="address">
            <div class="row">
              <div class="offCanvas__orgName-inputField col-md-6">
                <app-input-filed label="Address1" placeholder="Address1"
                  [control]="$any(getAddressControl)?.get('line1')"></app-input-filed>
              </div>
              <div class="offCanvas__orgName-inputField col-md-6">
                <app-input-filed label="Address2" placeholder="Address2"
                  [control]="$any(getAddressControl)?.get('line2')"></app-input-filed>
              </div>
            </div>
            <div class="row">
              <div class="offCanvas__orgName-inputField col-md-6">
                <app-input-filed label="City" placeholder="Enter City"
                  [control]="$any(getAddressControl)?.get('city')"></app-input-filed>
              </div>
              <div class="offCanvas__orgName-inputField col-md-6">
                <app-input-filed label="State" placeholder="Enter State"
                  [control]="$any(getAddressControl)?.get('state')"></app-input-filed>
              </div>
            </div>
            <div class="row">
              <div class="offCanvas__orgName-inputField col-md-6">
                <app-input-filed label="Country" placeholder="Enter Country"
                  [control]="$any(getAddressControl)?.get('country')"></app-input-filed>
              </div>
              <div class="offCanvas__orgName-inputField col-md-6">
                <app-input-filed label="Pincode" placeholder="Enter Pincode"
                  [control]="$any(getAddressControl)?.get('pincode')"></app-input-filed>
              </div>
            </div>
          </div>
          <ng-container>
            <div class="col-lg-12">
              <div formArrayName="userDetails">
                <div *ngFor="let phone of partyForms.controls; let i = index" [formGroupName]="i">
                  <div class="head">
                    <h4 class="card-title mt-0">Individual - {{ i + 1 }}</h4>
                  </div>
                  <div class="form-container">
                    <div class="form-row">
                      <div>
                      </div>
                      <div class=" ml-1 custom_delete">
                        <fa-icon [icon]="faTrash" class="custom_delete_faicon" (click)="deleteParty(i)"></fa-icon>
                      </div>
                      <div class="pb-2 name_block">
                        <app-input-filed label="Name" [value]="$any(partyForms).controls[i].controls['name'].value"
                          placeholder="Enter Name" [control]=" $any(partyForms).controls[i].controls['name']
                                                                  " [maxlength]="'50'"
                          [starIcon]="'*'"></app-input-filed>
                        <div
                          *ngIf="$any(partyForms).controls[i].controls['name'].invalid &&
                                                                    ($any(partyForms).controls[i].controls['name']?.dirty ||
                                                                    $any(partyForms).controls[i].controls['name'].touched)">
                          <div *ngIf="$any(partyForms).controls[i].controls['name'].errors?.['required']">
                            <small class="text-danger">Party Contact is required</small>
                          </div>
                        </div>
                      </div>
                      <div class="pb-2 name_block">
                        <app-input-filed label="Email" [value]="$any(partyForms).controls[i].controls['emailAddress'].value" placeholder="Enter Email" [control]="
                                                                    $any(partyForms).controls[i].controls['emailAddress']
                                                                  " [starIcon]="'*'"></app-input-filed>
                        <div
                          *ngIf="$any(partyForms).controls[i].controls['emailAddress'].invalid &&
                                                                    ($any(partyForms).controls[i].controls['emailAddress']?.dirty ||
                                                                    $any(partyForms).controls[i].controls['emailAddress'].touched)">
                          <div *ngIf="$any(partyForms).controls[i].controls['emailAddress'].errors?.['required']">
                            <small class="text-danger">Email required</small>
                          </div>
                          <div *ngIf="$any(partyForms).controls[i].controls['emailAddress'].errors?.['pattern']">
                            <small class="text-danger">Please enter valid email</small>
                          </div>
                        </div>
                      </div>
                      <div class="pb-2 name_block">
                        <app-input-filed label="Designation" [value]="$any(partyForms).controls[i].controls['designation'].value" placeholder="Enter Designation" [control]="
                                                                    $any(partyForms).controls[i].controls['designation']
                                                                  " [maxlength]="'50'"
                         ></app-input-filed>
                      </div>
                      <div class="name_block">
                        <label for="documentType" class="filterOffcanvas__inputlabel">Authorized Signatory<span class="icon_color">*</span></label>
                        <app-auto-search-dropdown  (selectedValues)="updatePermissions($event,i)" [previousSeletedValues]="$any(partyForms).controls[i].controls['permissions'].value" placeholder="Authorized Signatory" [listData]="permissionsList">
                        </app-auto-search-dropdown>
                      </div>
                      <div *ngIf="$any(partyForms).controls[i].controls['permissions']?.value?.includes('E-Sign')">
                          <div class="signatureType__dropdown">
                            <div class="signatureType__dropdownLabel">
                              <Script></Script>Signatory Type<span style="color: chocolate;">*</span>
                            </div>
                            <select class="signatureType__dropdownOptions" formControlName="signatoryType">
                              <option [ngValue]="'AuthorisedSignatory'">
                                AuthorisedSignatory
                              </option>
                              <option [ngValue]="'Witness'">
                                Witness
                              </option>
                            </select>
                          </div>
                          <div *ngIf="$any(partyForms).controls[i].controls['signatoryType'].errors?.['required']">
                            <small class="text-danger">Signatory Type required</small>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container>
                <div class="buttons d-flex gap-2">
                  <h4 class="offcanvas__orgDetailsTitle">Add Individual</h4>
                  <button type="button" (click)="addParty({})" class="btn btn-default custom-sm-btn">
                    <fa-icon [icon]="faPlus" style="font-size: 12px;"></fa-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="offCanvas__footer">
        <div class="text-danger" *ngIf="errorMessage">
          {{errorMessage}}
        </div>
        <app-common-button (clickEvent)="onCloseIconClick(false)" buttonClass="smallbutton"
          buttonLabel="Cancel"></app-common-button>
        <app-common-button (clickEvent)="onSubmit()" [loadingIcon]="verifyLoader" buttonClass="smallFilledButton"
          buttonLabel="Submit" [disabled]="editFormGroup.invalid"></app-common-button>
      </div>
    </form>
  </div>
</div>
