import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SdexAdminService } from '../../services/sdex/sdex-admin.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',

})
export class DeleteUserComponent {
  @Input() userId   : any;
  errorMsg          : any;

  constructor(
    public activeModal  : NgbActiveModal,
    private sdexService : SdexAdminService
  ) { }

  closeDialog() {
    this.activeModal.close();
  }

  deleteUser() {
    this.sdexService.deleteSubCompanyUser(this.userId)?.subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.activeModal.close({ status: true });
        }
      },
      error: (error: any) => {
        this.errorMsg = error?.error?.message;
      },
    });
  }
}
