import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-list-page-header',
  templateUrl: './list-page-header.component.html',
  styleUrls: ['./list-page-header.component.scss']
})

export class ListPageHeaderComponent {
  @Input() headerTitle        : string;
  @Input() headerDescription  : string;
  @Input() orgName           !: string;
  @Input() dashBoard         !: boolean;
  @Output() clearSearchEmit   : EventEmitter<any> = new EventEmitter<any>();

  clearSearch(){
    this.clearSearchEmit.emit(true);
  }
}
