export enum RegExpPatterns {
  EmailPattern = '^([a-zA-Z0-9][\\w.]+@[a-zA-Z]+(\\.[a-zA-Z]{2,5})*\\.[a-zA-Z]{2,5})$',
  PasswordPattern = '^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$',
  MobilePattern = '[6789][0-9]{9}',
  GstnPattren = '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$',
  PanNumberPattren = '[A-Z]{5}[0-9]{4}[A-Z]{1}',
  CinNumber = '^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$',
  AdharNumber = '^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$',
  MobileNumberPattern = '^[0-9]{10}+$',
  MicrPattern = '[0-9]{9}',
  roleNamePattern = '^[a-zA-Z][a-zA-Z0-9!@#$%^&*()_ :;<>,.?/~\\-]{2,15}$'
}
