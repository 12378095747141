<section class="editDocument__container">
    <header>
        <app-offcanvas-header paddingClass="paddingClass" header="{{ moduleItem ? 'Edit Module' : 'Add Module' }}"
            (closeClicked)="closeOffCanvas(false)"></app-offcanvas-header>
    </header>
    <form class="mt-3" [formGroup]="moduleFormGroup">
        <div class="offcanvas__content">
            <div class="offcanvas__orgDetails">
                <div class="offCanvas__orgName-inputField">
                    <app-input-filed label="Module Name"  [value]="$any(moduleFormGroup).controls['moduleName'].value"
                        placeholder="Enter Module" [control]="$any(moduleFormGroup).controls['moduleName']"
                        [starIcon]="'*'"></app-input-filed>
                        <div *ngIf="moduleFormGroup.controls?.['moduleName']?.invalid &&
                        (moduleFormGroup.controls?.['moduleName']?.dirty ||
                        moduleFormGroup.controls?.['moduleName']?.touched)">
                            <div *ngIf="moduleFormGroup.controls?.['moduleName']?.errors?.['required']">
                                <small class="text-danger">Module name required.</small>
                              </div>
                        </div>
                        <div *ngIf="errorMessage"  class="text-danger pt-3">
                            {{errorMessage}}
                          </div>
                </div>
            </div>
        </div>
    </form>

    <div class="editDocument__footer">
        <footer class="offCanvas__footer">
            <app-common-button buttonClass="smallbutton" buttonLabel="Cancel"
                (click)="closeOffCanvas(false)"></app-common-button>
            <div>
                <app-common-button [loadingIcon]="verifyLoader" buttonClass="smallFilledButton" buttonLabel="Save"
                    (click)="onSubmit()" [disabled]="moduleFormGroup.invalid"></app-common-button>
            </div>
            <!-- Edit documnet footer -->
        </footer>
    </div>

</section>