export const APPTYPES = {
    sdex: 'SDEX',
    dams: 'DAMS',
    clms:  'CLMS'
}


export const manualcomponents = [
    {
      formatType: 'Static',
      value: ''
    },
    {
      formatType: 'RS',
      regex: '[A-Za-z\\s]'
    },
    {
      formatType: 'DDMMYY',
      regex: '[0-9]{6}',
      len: 6
    },
    {
      formatType: 'DDMMYYYY',
      regex: '[0-9]{8}',
      len: 8
    },
    {
      formatType: 'DD',
      regex: '[0-9]{2}',
      len: 2
    },
    {
      formatType: 'MM',
      regex: '[0-9]{2}',
      len: 2
    },
    {
      formatType: 'YY',
      len: 2,
      regex: '[0-9]{2}'
    },
    {
      formatType: 'YYYY',
      regex: '[0-9]{4}',
      len: 4
    }
  ];

  export const automaticComponents = [
    {
      formatType: 'Static',
      value: ''
    },
    {
      formatType: 'RS',
      regex: '[A-Za-z\\s]'
    },
    {
      formatType: 'DDMMYY',
      regex: '[0-9]{6}',
      len: 6
    },
    {
      formatType: 'DDMMYYYY',
      regex: '[0-9]{8}',
      len: 8
    },
    {
      formatType: 'DD',
      regex: '[0-9]{2}',
      len: 2
    },
    {
      formatType: 'MM',
      regex: '[0-9]{2}',
      len: 2
    },
    {
      formatType: 'YY',
      len: 2,
      regex: '[0-9]{2}'
    },
    {
      formatType: 'YYYY',
      regex: '[0-9]{4}',
      len: 4
    },
    {
      formatType: 'Sequence',
      startAt: 1
    },
  ];