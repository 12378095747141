<div class="card m-5" style="width: 18rem;">
    <div class="card-body">
      <h5 class="card-title">File Upload</h5>
      <div *ngIf="!uploadedFileName; else fileInfo">
        <app-common-icon [loadingIcon]="isFileUploading" data-bs-toggle="tooltip" ngbTooltip="Upload File"
          [iconSrc]="'assets/Images/document-vault-images/Upload.svg'" (click)="triggerFileInputClick()">
        </app-common-icon>
        <span class="main-text">Upload Your File</span>
        <p class="description">Maximum of 10 MB to be selected</p>
        <input style="display: none" #file type="file"
        accept="application/pdf,application/vnd.ms-excel,image/*,application/msword,application/vnd.ms-powerpoint,officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats,.xls,.xlsx"
        (change)="upload($event)" />      
      </div>
      <ng-template #fileInfo>
        <div class="uploaded-file">
          <span>{{ uploadedFileName }}</span>
          <app-common-icon [iconSrc]="'assets/Images/user-management/Cross.svg'" ngbTooltip="Remove File"
            (click)="removeFile()">
          </app-common-icon>
        </div>
      </ng-template>
      <div *ngIf="uploadErrorMessage" class="alert alert-danger">
        {{ uploadErrorMessage }}
      </div>
      <form class="mb-3" [formGroup]="userForm">
        <div class="offcanvas__orgDetails">
          <div class="offCanvas__orgName-inputField">
            <app-input-filed label="Name" placeholder="Name" [control]="$any(userForm).controls['name']"
              [starIcon]="'*'">
            </app-input-filed>
            <div *ngIf="userForm.controls['name'].invalid && (userForm.controls['name'].dirty || userForm.controls['name'].touched)"
              class="alert_required">
              <small *ngIf="userForm.controls['name'].errors?.['required']" class="text-danger">Please enter a user name</small>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="offCanvas__phoneNumber-inputFiled">
            <app-input-filed label="Mobile Number" placeholder="Mobile Number" [maxDigits]="10" [starIcon]="'*'"
              [control]="$any(userForm).controls['phoneNumber']" [allowedType]="'number'">
            </app-input-filed>
            <div *ngIf="userForm.controls['phoneNumber'].invalid && (userForm.controls['phoneNumber'].dirty || userForm.controls['phoneNumber'].touched)"
              class="alert_required">
              <small *ngIf="userForm.controls['phoneNumber'].errors?.['required']" class="text-danger">Please enter phone number</small>
            </div>
            <div *ngIf="userForm.controls['phoneNumber'].errors?.['pattern']">
              <small class="text-danger">Please enter a valid mobile number</small>
            </div>
          </div>
        </div>
        <div class="buttons">
          <app-common-button buttonClass="smallFilledButton" [loadingIcon]="verifyLoader" buttonLabel="Try e-Sign"
            [disabled]="userForm.invalid" (clickEvent)="onSubmit()">
          </app-common-button>
        </div>
      </form>
    </div>
  </div>
