import { Component, EventEmitter,  Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faEdit, faArrowAltCircleDown, faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { LookupService } from 'src/app/share/services/lookup/lookup.service';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss'
})
export class ContactsComponent {
  public readonly faEdit               = faEdit;
  public readonly faArrowAltCircleUp   = faArrowAltCircleUp;
  public readonly faArrowAltCircleDown = faArrowAltCircleDown;
  contact               : boolean = false;
  isOverlayOpen         : boolean = false;
  contactItem           : any;
  errorMessage          : string;
  contactList           : any;
  selfDetails           : any = {};
  selectedContact       : any;
  selectedContactIndex  : number = -1;
  @Output() updateData  : EventEmitter<any> = new EventEmitter();
  isLoadRecords: boolean = false;
  contactsCount: number = 0;
  isToster      : boolean = false;
  message       : string;
  companySearchValue : FormControl =  new FormControl('');


  constructor(
    private lookupService: LookupService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
        this.selfDetails = this.storageService?.getDataFromLocalStorage('selfDetails');
    this.getContactCount();
    this.getContactsList(0, '');

    this.companySearchValue?.valueChanges.pipe(debounceTime(1000))
    .pipe(distinctUntilChanged()).subscribe((res: any) => {
      this.getContactsList(0, this.companySearchValue?.value || '');
    })
  }

  openOverlay(selectedRow: any) {
    this.isOverlayOpen = true;
    this.contactItem = selectedRow;
    this.selectedContact = {};
    this.selectedContactIndex = -1;

  }

  closeOverlay(isSaved: boolean) {
    if (isSaved) {
      this.updateData.emit(true);
      this.isToster = true;
      this.message = this.contactItem ? 'Network updated successfully' : 'Network created successfully';
    }
    this.isOverlayOpen = false;
    this.getContactsList(0, '');
  }

  getContactsList(page:number,searchType : string) {
    this.isLoadRecords = true;
    this.lookupService?.getContactList({name_like : searchType,page})?.subscribe({
      next: (res) => {
        this.isLoadRecords = false;
        if (res.status === 'success') {
          this.contactList = res.data;
        }
      }, error: (error) => {
        this.isLoadRecords = false;
        this.errorMessage = error.error?.error?.message;
      }
    });
  }

  getContactCount() {
    this.lookupService?.getContactsCount()?.subscribe({
      next : (res: any)=> {
        this.contactsCount = res?.data?.count
      }
    })
  }

  handlePaginationPageChange(event : any){
    this.getContactsList(event.page, '')
  }

  toggleAdditionalRow(index: number, contact: any) {
    if (this.selectedContactIndex === index) {
      this.selectedContactIndex = -1;
    } else {
      this.selectedContact = contact;
      this.selectedContactIndex = index;
    }
  }

  closeToster(event: any){
    this.isToster = false;
    this.message = '';
  }
}
