<div *ngIf="tableData && tableData.length > 0">
    <table class="custom-table-list" aria-label="cred_table">
        <thead>
            <tr class="custom-table-list-headings">
                <th class="custom-col-width-30">Name</th>
                <th class="custom-col-width-50">Description</th>
                <th class="custom-col-width-20">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr class="custom-table-list-row-main" *ngFor="let type of tableData">
                <td class="custom-col-width-30">{{type.name}}</td>
                <td class="custom-col-width-50">{{type.description}}</td>
                <td class="custom-col-width-20">
                    <span class="d-flex justify-content-start">
                        <span (click)="openOverlay('edit',type)" data-bs-toggle="tooltip" ngbTooltip="Edit"
                            [placement]="'left'">
                            <div tooltipClass="custom-tooltip" class="btn btn-primary btn-sm me-2"
                                style="background-color: #de7141; border-color: #de7141;">
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </div>
                        </span>
                        <span (click)="deleteMasterData(type)" data-bs-toggle="tooltip" ngbTooltip="Delete"
                            [placement]="'right'">
                            <div class="btn btn-primary btn-sm me-2 fa-icon-class"
                                style="background-color: #de7141; border-color: #de7141;">
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </div>
                        </span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <div>
        <div class="overlay {{isOverlayOpen ? 'show' : ''}} ">
        </div>
        <div class="off-canvas width-450 {{isOverlayOpen ? 'show' : ''}}">
            <div class="off-canvas-content">
                <app-clms-add-contract-type [selectedTypeData]="selectedTypeData"
                    (closePanel)="closeOverlay($event)" [selectedType]="selectedType"></app-clms-add-contract-type>
            </div>
        </div>
    </div>
</div>