<button [ngClass]="disabled ? ( disableButtonClass || 'disabledBtn') : (typeBtn? [buttonClass, 'custButtonClass'] : buttonClass)" type="{{type}}" [disabled]="disabled"
  (click)="onClickEvent($event)">
  <span *ngIf="!loadingIcon">
    <img *ngIf="isImg" class="img-google" src="{{isImg}}" alt="">
    {{buttonLabel}}
  </span>
  <span *ngIf="loadingIcon">
    <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
  </span>
</button>
