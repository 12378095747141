import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LookupService } from 'src/app/share/services/lookup/lookup.service';

@Component({
  selector: 'app-add-module-type',
  templateUrl: './add-module-type.component.html',
  styleUrl: './add-module-type.component.scss'
})
export class AddModuleTypeComponent {
  @Input() moduleItem   : any;
  @Output() closePanel  : EventEmitter<boolean> = new EventEmitter<boolean>();
  moduleFormGroup      !: FormGroup;
  verifyLoader          : boolean = false;
  moduleList            : any;
  errorMessage          : string;
  addedFields           : any[] = [];


  constructor(private _fb: FormBuilder,
    private lookupService: LookupService
  ) { }

  ngOnInit(): void {
    this.createFormGroup();
    if (this.moduleItem?._id) {
      this.editModuleList(this.moduleItem);
    }
  }

  createFormGroup() {
    this.moduleFormGroup = this._fb.group({
      moduleName: new FormControl('', [
        Validators.required
      ]),
  
    })
  }

  closeOffCanvas(value: boolean = false) {
    this.closePanel.emit(value);
  }

  editModuleList(item: any) {
    this.moduleFormGroup.patchValue({
      moduleName: item.module
    });
  }

  onSubmit() {
    this.verifyLoader = true;
    const payload = {
      moduleName: this.moduleFormGroup.value.moduleName
    }
    if (this.moduleItem?._id) {
      this.lookupService.editModule(payload, this.moduleItem._id).subscribe({
        next: (res) => {
          if (res) {
            this.moduleList = res.data;
            this.verifyLoader = false;
            this.moduleItem = null;
            this.closeOffCanvas(true);
          }
        }, error: (err) => {
          this.errorMessage = err.error?.error?.message;
          this.verifyLoader = false;
        }
      });
    }
    else {
      this.lookupService.createModule(payload)?.subscribe({
        next: (res) => {
         if(res['success']){
          this.moduleList = res.data;
          this.verifyLoader = false;
          this.closeOffCanvas(true);
         }
        }, error: (err) => {
          this.errorMessage = err.error?.error?.message;
          this.verifyLoader = false;
        }
      })
      this.addedFields = [];
    }
  }
}
