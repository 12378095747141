import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(private http: HttpClient) { }


  createMetadata(payload: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-lookup/document-metadata`;
    return this.http.post(url, payload);
  }

  getMetadatalist(rootCompany: any, status?: any): Observable<any> {
    let params = new HttpParams();
    if (status) {
      params = params.append('status', status);
    }
    let url = environment.API_URL + `/v1/company-lookup/` + rootCompany + `/document-metadata`;
    return this.http.get(url, { params });
  }
  editMetadata(payload: any, _id: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-lookup/` + _id + `/document-metadata`;
    return this.http.put(url, payload)
  }

  disableMetadata(_id: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-lookup/` + _id + `/update-status`;
    return this.http.put(url, null)
  }

  getDocumentTypeList(): Observable<any> {
    let url = environment.API_URL + `/v1/company-doc-types`;
    return this.http.get(url);
  }

  createDocumentType(payload: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-doc-types`;
    return this.http.post(url, payload);
  }

  editDocumentType(payload: any, _id: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-doc-types/` + _id;
    return this.http.put(url, payload);
  }

  deleteDocumentType(_id: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-doc-types/` + _id;
    return this.http.delete(url);
  }

  getModuleRefernce(): Observable<any> {
    let url = environment.API_URL + `/v1/company-doc-modules`;
    return this.http.get(url)
  }
  getModulelist(): Observable<any> {
    let url = environment.API_URL + `/v1/company-doc-modules`;
    return this.http.get(url);
  }

  createModule(payload: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-doc-modules`;
    return this.http.post(url, payload);
  }

  editModule(payload: any, _id: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-doc-modules/` + _id + `/update-module`;
    return this.http.put(url, payload);
  }

  getMetadataByDocId(companyDocId: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-lookup/${companyDocId}/document-metadata`;
    return this.http.get(url);
  }
  getFieldHistory(fieldId: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-lookup/${fieldId}/versioning-history`;
    return this.http.get(url);
  }

  updateContractType(payload: any, _id: any): Observable<any> {
    let url = environment.API_URL + `/v1/cms-master/${_id}`;
    return this.http.put(url, payload);
  }

  deleteContractType(_id: any): Observable<any> {
    let url = environment.API_URL + `/v1/cms-master/${_id}`;
    return this.http.delete(url);
  }

  addContractType(payload: any): Observable<any> {
    let url = environment.API_URL + `/v1/cms-master`;
    return this.http.post(url, payload);
  }

  getMasterList(params: any): Observable<any> {
    let url = environment.API_URL + `/v1/cms-master`;
    return this.http.get(url, { params });
  }

  getContactList(params: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-guests`;
    return this.http.get(url, {params});
  }

  getContactsCount(): Observable<any> {
    let url = `${environment.API_URL}/v1/company-guests/count`;
    return this.http.get(url);
  }

  addContactList(payload: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-guests`;
    return this.http.post(url,payload);
  }

  editContactList(payload: any, _id: any): Observable<any> {
    let url = environment.API_URL + `/v1/company-guests/${_id}`;
    return this.http.put(url,payload);
  }

  // getting guest contract list

  getGuestContactList(params: any): Observable<any> {
    let url = environment.API_URL + `/v1/guest-users/companies-list`;
    return this.http.get(url, {params});
  }

}

